import { initContractsPartial, azimuth } from "azimuth-js";
import Web3 from "web3";
import * as c from "../../constants";
import ob from "urbit-ob";
import * as utils from "../../utils";

export const GET_PLANETS_FOR_SALE = "GET_PLANETS_FOR_SALE";
export const GET_LISTED_SHIPS = "GET_LISTED_SHIPS";
export const GET_CHILDREN = "GET_CHILDREN";
export const GET_WALLET_ADDRESS = "GET_WALLET_ADDRESS";

export const SET_PRICE_FILTER = "SET_PRICE_FILTER";
export const SET_CHILD_RANGE_FILTER = "SET_CHILD_RANGE_FILTER";
export const SET_SHIP_FILTER = "SET_SHIP_FILTER";
export const SET_PROPERTIES_FILTER = "SET_PROPERTIES_FILTER";
export const SET_CHILD_FILTER = "SET_CHILD_FILTER";
export const SET_SPECIAL_FILTER = "SET_SPECIAL_FILTER";
export const SET_CURRENCY_FILTER = "SET_CURRENCY_FILTER";

export const CLEAR_CHILDREN = "CLEAR_CHILDREN";
export const LIST_FOR_SALE = "LIST_FOR_SALE";
export const GET_ETH_PRICE = "GET_ETH_PRICE";
export const GET_NEXT_DATA = "GET_NEXT_DATA";
export const GET_SPONSORING = "GET_SPONSORING";
export const GET_ALL_GALAXIES = "GET_ALL_GALAXIES";
export const SET_UNBOOTED_FILTER = "SET_UNBOOTED_FILTER";
export const GET_TRANSACTIONS = "GET_TRANSACTIONS";
export const SET_CONTRACTS = "SET_CONTRACTS";
export const ADD_TO_BASKET = "ADD_TO_BASKET";
export const REMOVE_FROM_BASKET = "REMOVE_FROM_BASKET";
export const CLEAR_BASKET = "CLEAR_BASKET";
export const SET_COLOR = "SET_COLOR";
export const SET_SKIN = "SET_SKIN";

export const DISMISS_FAQ_MESSAGE = "DISMISS_FAQ_MESSAGE";

export const getSponsoring = (ship) => {
  return async (dispatch) => {
    // Note that this is duplication of useContracts()
    const provider = new Web3.providers.HttpProvider(c.INFURA_URL);
    const web3 = new Web3(provider);
    const contracts = await initContractsPartial(
      web3,
      c.CONTRACT.azimuth
      // azimuth.azimuth.mainnet
    );

    const points = await azimuth.getSponsoring(contracts, parseInt(ship.point));

    const removeSelf = points.filter((p) => p != parseInt(ship.point));

    // const limitPoints = points.slice(0, 300);
    const result = points && utils.getNamesFromPointsAzimuth(removeSelf);

    // dispatch({
    //   type: GET_SPONSORING,
    //   payload: result,
    // });

    return result;
  };
};

export const getChildren = (point) => {
  const result = utils.getChildrenCalc(point);

  return {
    type: GET_CHILDREN,
    payload: result,
  };
};

export const clearChildren = () => {
  return { type: CLEAR_CHILDREN };
};

export const setPriceFilter = (priceObj) => {
  return async (dispatch) => {
    dispatch({ type: SET_PRICE_FILTER, payload: priceObj });
  };
};

export const setSpecialFilter = (val) => {
  return async (dispatch) => {
    dispatch({ type: SET_SPECIAL_FILTER, payload: val });
  };
};

export const setCurrencyFilter = (val) => {
  return async (dispatch) => {
    dispatch({ type: SET_CURRENCY_FILTER, payload: val });
  };
};

export const setChildRangeFilter = (rangeObj) => {
  return async (dispatch) => {
    dispatch({ type: SET_CHILD_RANGE_FILTER, payload: rangeObj });
  };
};

export const setShipFilter = (ship) => {
  return async (dispatch) => {
    dispatch({ type: SET_SHIP_FILTER, payload: ship });
  };
};

export const setChildFilter = (filterObj) => {
  return async (dispatch) => {
    dispatch({ type: SET_CHILD_FILTER, payload: filterObj });
  };
};

export const setPropertiesFilter = (val) => {
  return async (dispatch) => {
    dispatch({
      type: SET_PROPERTIES_FILTER,
      payload: val,
    });
  };
};

export const setUnbootedFilter = (val) => {
  return { type: SET_UNBOOTED_FILTER, payload: val };
};

export const setContracts = (val) => {
  return async (dispatch) => {
    dispatch({ type: SET_CONTRACTS, payload: val });
  };
};

export const setColor = (val) => {
  return async (dispatch) => {
    dispatch({ type: SET_COLOR, payload: val });
  };
};

export const setSkin = (val, loc) => {
  return async (dispatch) => {
    // async necessary on these?

    val
      ? window.history.replaceState(null, "", `${loc}?style=emblemish`)
      : window.history.replaceState(null, "", `${loc}`);

    dispatch({ type: SET_SKIN, payload: val });
  };
};

export const getNextData = (val) => {
  return async (dispatch) => {
    dispatch({ type: GET_NEXT_DATA, payload: val });
  };
};

export const getAllGalaxies = (ship) => {
  return async (dispatch) => {
    var points = [];
    for (var i = 0; i < 256; i++) {
      points.push(i);
    }

    const result = utils.getNamesFromPointsAzimuth(points);

    dispatch({
      type: GET_ALL_GALAXIES,
      payload: { galaxies: result },
    });
  };
};

export const listForSale = (data) => {
  return async (dispatch) => {
    const apiUrl = `${c.API_URL}/list_for_sale/`;

    const response = await fetch(apiUrl, {
      headers: {
        "Content-Type": "application/json",
      },
      body: data,
    });

    const response2 = await response.json();
    const points = response2 && response2.map((obj) => obj.point);
    const result2 = response && utils.getNamesFromPoints(points);

    dispatch({
      type: LIST_FOR_SALE,
      payload: { slice: result2 },
    });
  };
};

export const getListed = (params) => {
  return async (dispatch) => {
    const apiUrl = `${c.API_URL}/get_listed_ships?type=${encodeURIComponent(
      params?.type //wrong
    )}&price_min=${encodeURIComponent(
      params?.price_min
    )}&price_max=${encodeURIComponent(
      params?.price_max
    )}&search_phrase=${encodeURIComponent(
      params?.search_phrase
    )}&unbooted=${encodeURIComponent(
      params?.unbooted
    )}&sort_order=${encodeURIComponent(params?.sort_order || "price_int")}`;

    const response = await fetch(apiUrl, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    const result = await response.json();
    const ships = result.data;

    // const points = response2 && response2.map((obj) => obj.point);
    const result2 = ships && utils.getNamesFromPoints(ships);

    dispatch({
      type: GET_LISTED_SHIPS,
      payload: { slice: result2 },
    });
  };
};

export const getEthPrice = () => {
  return async (dispatch) => {
    const price = await utils.convertToUsd(1);
    dispatch({
      type: GET_ETH_PRICE,
      payload: price,
    });
  };
};

export const removeForSale = (ship, address) => {
  return async (dispatch) => {
    const apiUrl = `${c.API_URL}/remove_for_sale`;
    const response = await fetch(apiUrl, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ azpoint: ship.point, address: address }),
    });
  };
};

export const getTransactions = () => {
  return async (dispatch) => {
    const apiUrl = `${c.API_URL}/get_transactions`;
    // probably want date range as params

    const response = await fetch(apiUrl, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    const txns = await response.json();

    const result = txns && utils.getNamesFromPoints(txns);

    dispatch({
      type: GET_TRANSACTIONS,
      payload: result,
    });
  };
};

export const addToBasket = (point) => {
  return { type: ADD_TO_BASKET, payload: point };
};

export const removeFromBasket = (point) => {
  return { type: REMOVE_FROM_BASKET, payload: point };
};

export const clearBasket = () => {
  return { type: CLEAR_BASKET };
};

export const dismissFaqMessage = () => {
  return { type: DISMISS_FAQ_MESSAGE };
};
