import { ethers } from "ethers";
// import { Pool } from "@uniswap/v3-sdk";
// import { Token } from "@uniswap/sdk-core";
import { abi as IUniswapV3PoolABI } from "@uniswap/v3-core/artifacts/contracts/interfaces/IUniswapV3Pool.sol/IUniswapV3Pool.json";

const provider = new ethers.providers.JsonRpcProvider(
  `https://mainnet.infura.io/v3/07b8b02b7b80417b924c57daa08108fe`
);

const poolAddress = "0x7f4a0822a6e3c085df795a8d78acbcd36f210ebf";

const poolContract = new ethers.Contract(
  poolAddress,
  IUniswapV3PoolABI,
  provider
);

async function getPoolImmutables() {
  const [factory, token0, token1, fee, tickSpacing, maxLiquidityPerTick] =
    await Promise.all([
      poolContract.factory(),
      poolContract.token0(),
      poolContract.token1(),
      poolContract.fee(),
      poolContract.tickSpacing(),
      poolContract.maxLiquidityPerTick(),
    ]);

  const immutables = {
    factory,
    token0,
    token1,
    fee,
    tickSpacing,
    maxLiquidityPerTick,
  };
  return immutables;
}

async function getPoolState() {
  const [liquidity, slot] = await Promise.all([
    poolContract.liquidity(),
    poolContract.slot0(),
  ]);

  const PoolState = {
    // liquidity,
    sqrtPriceX96: slot[0],
    // tick: slot[1],
    // observationIndex: slot[2],
    // observationCardinality: slot[3],
    // observationCardinalityNext: slot[4],
    // feeProtocol: slot[5],
    // unlocked: slot[6],
  };

  return PoolState;
}

export const main = async () => {
  const [immutables, state] = await Promise.all([
    getPoolImmutables(),
    getPoolState(),
  ]);

  // const TokenA = new Token(3, immutables.token0, 2, "USDC", "USD Coin");

  // const TokenB = new Token(3, immutables.token1, 2, "WSTR", "Wrapped Star");

  // const poolExample = new Pool(
  //   TokenA,
  //   TokenB,
  //   immutables.fee,
  //   state.sqrtPriceX96.toString(),
  //   state.liquidity.toString(),
  //   state.tick
  // );

  // console.log((state.sqrtPriceX96 / 2 ** 96) ** 2);
  // console.log(2 ** 192 / state.sqrtPriceX96 ** 2);
  return 2 ** 192 / state.sqrtPriceX96 ** 2;
};
