import Sigil from "./Sigil";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import * as utils from "../utils";
import React, { useEffect, useRef, useState } from "react";
import { Spinner } from "react-bootstrap";
import ReactTooltip from "react-tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartPlus } from "@fortawesome/free-solid-svg-icons";
import Fade from "../components/Fade";
import TouchableOpacity from "./TouchableOpacity";
import { BrowserView, MobileView } from "react-device-detect";

const Card = (props) => {
  const {
    ship,
    size,
    price,
    colors,
    showColor,
    disabled,
    isOwner,
    routeToSell,
    routeToTransfer,
    routeToGalaxyListing,
    isMobile,
    showRare,
    currency,
  } = props;
  const history = useHistory();
  const priceInEth = price && utils.gweiToEth(price);
  const [clickLoading, setClickLoading] = useState(false);
  const hasSpawned = ship.ship_type == "star" && ship.child_points ? `*` : "";
  const ref = useRef(null);
  const [visible, setVisible] = useState(false);

  const [randomColor, setRandomColor] = useState({
    shape: "#e6e6e6",
    background: "#e6e6e6",
  });

  useEffect(() => {
    // if (ship.point <= 65535) {
    // setRandomColor({ shape: "black", background: "white" });
    // return;
    // }

    if (colors && colors[0] != null) {
      setRandomColor({ shape: colors[0], background: colors[1] });
    } else {
      const rndColor1 = !showColor ? "black" : utils.getColorSkin(ship.patp)[0];
      const rndColor2 = !showColor ? "white" : utils.getColorSkin(ship.patp)[1];

      // const rndColor1 = !showColor ? "black" : utils.getRandomColor();
      // const rndColor2 = !showColor ? "white" : utils.getRandomColor();

      setRandomColor({ shape: rndColor1, background: rndColor2 });
    }
  }, [colors, ship]);

  const handlePress = async (event) => {
    if (isMobile) {
      props.handleModal();
      return;
    }
    event.stopPropagation();
    setClickLoading(true);

    let shipObj;
    if (!ship.is_listed) {
      const _ship = await utils.constructShipObjectsArray([ship.point]);
      shipObj = _ship[0];
    } else {
      shipObj = ship;
    }

    if (routeToSell) {
      shipObj && history.push("/sell", { ship: shipObj });
    } else if (routeToTransfer) {
      props.handleTransfer(ship);
    } else if (routeToGalaxyListing) {
      shipObj && history.push(`/galaxytransfer`, { galaxy: shipObj });
    } else {
      shipObj &&
        history.push(`/ship/${shipObj.patp}`, {
          ship: shipObj,
          // colors: [randomColor.shape, randomColor.background],
        });
    }
  };

  const handleAddToCart = (e) => {
    e.stopPropagation();

    props.add(ship);
  };

  return (
    <>
      <Button
        variant="primary"
        className="card2"
        onClick={disabled ? () => {} : handlePress}
        style={styles.container}
        onMouseDown={(e) => e.preventDefault()}
        onMouseEnter={() => setVisible(true)}
        onMouseLeave={() => setVisible(false)}
      >
        <div style={styles.sigil}>
          {/* <Sigil patp={ship.patp} size={size} colors={["black", "white"]} /> */}
          <Sigil
            patp={ship.patp}
            size={size}
            colors={[randomColor.shape, randomColor.background]}
          />
        </div>

        <div style={styles.priceContainer}>
          {clickLoading ? (
            <Spinner
              as="span"
              animation="border"
              role="status"
              aria-hidden="true"
              style={{
                height: 25,
                marginLeft: 20,
                width: 25,
                // color: "rgb(0,30,200,0.7",
                color: "black",
              }}
            />
          ) : (
            <>
              {/* <ReactTooltip id="sample2" type="info" /> */}
              <div
                ref={ref}
                data-for="sample2"
                data-event="fakeEvent"
                onMouseLeave={() => ReactTooltip.hide(ref.current)}
                onMouseEnter={() =>
                  ship?.child_points &&
                  ship?.ship_type == "star" &&
                  ReactTooltip.show(ref.current)
                }
                data-tip={`this star has spawned ${ship.child_points} planets`}
                style={styles.patp}
              >
                <div
                  style={{ flex: 1, display: "flex", width: "100%" }}
                >{`${ship?.patp} ${hasSpawned}`}</div>
                {visible && ship.unspawned && (
                  <BrowserView>
                    <TouchableOpacity onPress={handleAddToCart}>
                      <FontAwesomeIcon
                        icon={faCartPlus}
                        style={{
                          fontSize: 16,
                          marginRight: 20,
                          // color: "rgba(28, 64, 184,0.9)",
                        }}
                      />
                    </TouchableOpacity>
                  </BrowserView>
                )}
              </div>

              {
                <div style={{ display: "flex", width: "100%" }}>
                  <div style={{ flex: 1 }}>
                    {price ? (
                      <div style={{ textAlign: "left" }}>
                        <img
                          src={utils.getLogo(currency)}
                          style={styles.ethLogo}
                        />
                        <b style={styles.price}>{priceInEth}</b>
                      </div>
                    ) : null}
                  </div>
                  <Fade isOn={showRare}>
                    {ship?.rareness && showRare ? (
                      <div
                        style={{
                          backgroundColor: "rgba(28, 64, 184,0.9)",
                          // backgroundColor: "#5D93C4",
                          borderRadius: 6,
                          color: "white",
                          padding: 4,
                          fontSize: 12,
                          width: 58,
                          marginTop: 5,
                        }}
                      >
                        <b>{ship?.rareness.toFixed(3)}</b>
                      </div>
                    ) : null}
                  </Fade>
                </div>
              }
            </>
          )}
        </div>
      </Button>
    </>
  );
};

const styles = {
  container: {
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    // borderWidth: 1,
    borderColor: "#e3e3e3",
    borderStyle: "solid",
    marginLeft: 7,
    marginRight: 7,
    padding: 0,
    paddingBottom: 30,
    borderRadius: 15,
    backgroundColor: "white",
    outline: "none",
  },
  priceContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    height: 40,
    // justifyContent: "space-between",
    paddingRight: 9,
    paddingLeft: 9,
  },
  price: {
    fontSize: 13,
    color: "black",
  },
  ethLogo: {
    width: 15,
    height: 15,
    marginRight: 8,
  },
  sigil: {
    display: "flex",
    marginBottom: 20,
    borderTopLeftRadius: 12,
    borderTopRightRadius: 12,
    borderWidth: 0,
    overflow: "hidden",
  },
  patp: {
    color: "black",
    fontSize: 14,
    fontWeight: "bold",
    display: "flex",
    flex: 1,
    width: "100%",
  },
  forSale: {
    fontSize: 13,
    color: "black",
  },
};

export default Card;
