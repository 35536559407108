import React, { useEffect, useState } from "react";
import metamask from "../assets/img/metamask-fox.svg";
import Modal from "react-modal";
import * as utils from "../utils";
import * as c from "../constants";

const ContactModal = (props) => {
  const renderMessage = () => {
    return (
      <div
        style={{
          fontFamily: "Inter",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",

          fontWeight: 600,
          //   color: "gray",
          paddingTop: 20,
          paddingLeft: 20,
          paddingRight: 20,
          backgroundColor: "white",
        }}
      >
        <div style={{ fontSize: 24, marginBottom: 10 }}>Contact</div>

        {/* <div style={{ fontWeight: 400, fontSize: 14, marginBottom: 10 }}>
          <b>Marketplace:</b> {c.CONTRACT_ADDRESS.MAINNET.exchange}
        </div>

        <div style={{ fontWeight: 400, fontSize: 14, marginBottom: 10 }}>
          <b>Spawn sale:</b> {c.CONTRACT_ADDRESS.MAINNET.planetSale}
        </div> */}

        <div style={{ fontSize: 24, marginBottom: 30 }}></div>

        <div style={{ fontWeight: 400, fontSize: 14 }}>
          <b>email:</b> info@urbitex.io
        </div>
        {/* <div style={{ fontWeight: 400, fontSize: 14 }}>
          <b>urbit:</b> ~dosdel-falrud
        </div> */}

        {/* add contracts */}
        {/* <div style={{ fontWeight: 400, fontSize: 14 }}>
          <b>urbit group:</b> ~rolryx/urbitex
        </div> */}
      </div>
    );
  };

  return (
    <Modal
      ariaHideApp={false}
      isOpen={props.modalState}
      onAfterOpen={() => {}}
      onRequestClose={props.toggleModal}
      style={styles.customStyles}
      contentLabel="Contact"
    >
      {renderMessage()}

      <div
        style={{
          display: "flex",
          marginTop: 30,
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <button
          className="btn btn-outline-dark btn-lg"
          style={{
            height: 50,
            borderRadius: 12,
            border: "solid",
            borderWidth: 0.1,
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            borderColor: "rgb(0,30,100,0.3)",
            // paddingLeft: 30,
            fontFamily: "Inter",
            fontWeight: 600,
            fontSize: 18,
            boxShadow: "1px 1px 10px 0px rgba(0,0,0,0.1)",
            width: 100,
            margin: 20,
            // marginLeft: 15,
            // marginBottom: 50,
          }}
          onClick={props.toggleModal}
        >
          Close
        </button>
      </div>
    </Modal>
  );
};

const styles = {
  customStyles: {
    backgroundColor: "white",
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      borderRadius: 15,
      boxShadow: "1px 1px 10px 1px rgba(0,0,0,0.1)",
      padding: 0,
      // height: 200,
      width: 300,
      zIndex: 100,
      backgroundColor: "white",
    },
  },
};

export default ContactModal;
