import React, { useState, useEffect } from "react";

const TouchableOpacity = (props) => {
  const { children, onPress, disabled, width } = props;
  const [styleOpacity, setStyleOpacity] = useState(1);

  return (
    <div
      style={{
        opacity: disabled ? 1 : styleOpacity,
        cursor: "pointer",
        width,
      }}
      onMouseEnter={() => setStyleOpacity(0.7)}
      onMouseLeave={() => setStyleOpacity(1)}
      onClick={disabled ? null : onPress}
      onMouseDown={() => setStyleOpacity(0.5)}
      onMouseUp={() => setStyleOpacity(1)}
    >
      {children}
    </div>
  );
};

const styles = {
  container: {},
};

export default TouchableOpacity;
