import React, { lazy, Suspense } from "react";
import {
  Switch,
  Route,
  Redirect,
  BrowserRouter as Router,
} from "react-router-dom";

// import VisualizerScreen from "../screens/VisualizerScreen";
// import DebugScreen from "../screens/DebugScreen";
// import AboutScreen from "../screens/AboutScreen";
// import GalaxySaleScreen2 from "../screens/GalaxySaleScreen2";
import HomeScreen from "../screens/LandingPage";
import FallBackScreen from "../screens/FallbackScreen";
// import TestScreen from "../screens/TestScreen";
const MarketScreen = lazy(() => import("../screens/MarketScreen"));
const VisualizerScreen = lazy(() => import("../screens/VisualizerScreen"));
const AccountScreen = lazy(() => import("../screens/AccountScreen"));
const NetworkExplorerScreen = lazy(() =>
  import("../screens/NetworkExplorerScreen")
);
const ListSaleScreenNew = lazy(() => import("../screens/ListSaleScreenNew"));
const ShipDetailsScreen3 = lazy(() => import("../screens/ShipDetailsScreen3"));
const TxnScreen = lazy(() => import("../screens/TxnScreen"));
const NotFoundScreen = lazy(() => import("../screens/NotFoundScreen"));
const AddressScreen = lazy(() => import("../screens/AddressScreen"));
const GalaxyExplorer = lazy(() => import("../screens/GalaxyExplorer"));
const CreatePoolScreen = lazy(() => import("../screens/CreatePoolScreen"));
const FaqScreen = lazy(() => import("../screens/faqScreen"));
const PoolsDashboardScreen = lazy(() =>
  import("../screens/PoolsDashboardScreen")
);

const PoolsScreen = lazy(() => import("../screens/MyPoolsScreen"));
const AllPoolsScreen = lazy(() => import("../screens/AllPoolsScreen"));

const SwapScreen = lazy(() => import("../screens/SwapScreen7"));

const GalaxyDetailsScreen = lazy(() =>
  import("../screens/GalaxyDetailsScreen")
);

const AppSwitch = (props) => {
  return (
    <Router>
      <Suspense fallback={<FallBackScreen />}>
        <Switch>
          <Route path="/" exact component={HomeScreen} />

          <Route path="/fallback" exact component={FallBackScreen} />
          <Route path="/home" exact component={HomeScreen} />

          <Route path="/market" component={MarketScreen} />
          <Route path="/network" component={GalaxyExplorer} />
          <Route path="/data" component={TxnScreen} />
          <Route path="/visualizer/:patp?" component={VisualizerScreen} />
          <Route path="/explore/:patp" component={NetworkExplorerScreen} />
          <Route path="/account" component={AccountScreen} />
          <Route path="/wallet" component={AccountScreen} />
          <Route path="/sell" component={ListSaleScreenNew} />
          <Route path="/ship/:patp" component={ShipDetailsScreen3} />
          <Route path="/txn" component={TxnScreen} />
          <Route path="/address/:address" component={AddressScreen} />
          <Route path="/stats" component={GalaxyDetailsScreen} />
          <Route path="/swap" component={SwapScreen} />
          <Route path="/create" component={CreatePoolScreen} />
          <Route path="/faq" component={FaqScreen} />
          <Route path="/my-pools" component={PoolsDashboardScreen} />
          <Route path="/all-pools" component={AllPoolsScreen} />
          {/* <Route path="/galaxy-sale" component={GalaxySaleScreen2} /> */}
          {/* <Route path="/test" component={TestScreen} /> */}

          <Route path="/pools/:address" component={PoolsScreen} />

          <Route path="/404" component={NotFoundScreen} />
          {/* <Route path="/test" component={DebugScreen} /> */}

          <Redirect to="/404" />
        </Switch>
      </Suspense>
    </Router>
  );
};

export default AppSwitch;
