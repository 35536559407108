import { useEffect, useState } from "react";
import { initContractsPartial } from "azimuth-js";
import Web3 from "web3";
import * as c from "../constants";
import * as utils from "../utils";
import { useDispatch, useSelector } from "react-redux";
import { setContracts } from "../store/actions/planets";

export const useContracts = () => {
  const dispatch = useDispatch();

  const _contracts = useSelector((state) => state.planets.contracts);

  const asyncFunction = async () => {
    const provider = new Web3.providers.HttpProvider(c.INFURA_URL);
    const web3 = new Web3(provider);
    const contracts = await initContractsPartial(
      web3,
      c.CONTRACT.azimuth
      // azimuth.azimuth.mainnnet
      // "0x308ab6a6024cf198b57e008d0ac9ad0219886579"
    );

    dispatch(setContracts(contracts));
    // setContracts(contracts);
  };

  !_contracts && asyncFunction();

  return _contracts;
};

export const useAccount = () => {
  const [address, setAddress] = useState();

  useEffect(() => {
    const asyncFunction = async () => {
      const ethereum = window.ethereum;
      if (ethereum) {
        if (!ethereum.selectedAddress) {
          await ethereum.enable();
        }
        const addressCheckSum = Web3.utils.toChecksumAddress(
          ethereum.selectedAddress
        );
        setAddress(addressCheckSum);
      }
    };
    asyncFunction();
  }, []);

  return address;
};
