import React from "react";
import { Spinner } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { useHistory } from "react-router-dom";

const TopBar = (props) => {
  const textColor = "#c9c9c9";
  const { spin, showBack } = props;
  const history = useHistory();
  const renderSpin = () => {
    return (
      <div style={{ fontWeight: 300, fontSize: 20 }}>
        <Spinner
          as="span"
          animation="border"
          // animation="grow"
          role="status"
          aria-hidden="true"
          // style={{ color: "#303030" }}
          style={{ color: "#696969" }}
        />
      </div>
    );
  };

  return (
    <div
      style={{
        // backgroundColor: "#051F37",
        // backgroundColor: "black",
        // backgroundColor: "rgba(0,0,0,0.9)",
        backgroundColor: "#07091c",
        display: "flex",
        flex: 1,
        alignItems: "center",
        // justifyContent: "flex-end",
        height: 80,
      }}
    >
      <div
        style={{
          display: "flex",
          paddingLeft: 53,
          // marginTop: 20,
          fontFamily: "Inter",
          fontWeight: 800,
          fontSize: 26,
          color: "white",
        }}
      >
        {spin && renderSpin()}

        {props.title}
      </div>

      <div style={{ flex: 1 }}></div>
      {showBack && (
        <Button
          className="btn btn-primary"
          style={{
            //   borderRadius: 99,
            fontWeight: "bold",
            fontSize: 14,
            marginRight: 50,
            width: 100,
            height: 40,
          }}
          onClick={() => history.push("/swap")}
        >
          Back
        </Button>
      )}
    </div>
  );
};

export default TopBar;
