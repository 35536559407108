import React from "react";
import { Link } from "react-router-dom";

const Footer = (props) => {
  return (
    <footer>
      <div
        className="d-flex justify-content-center text-center p-5"
        style={{ backgroundColor: "rgba(51,51,51,0.97)" }}
      >
        <div className="#" style={{ textAlign: "center" }}>
          <br />
          {/* <Link to="/swap" style={styles.labels}>
            Swap
          </Link> */}

          <Link to="/market" style={styles.labels}>
            Marketplace
          </Link>

          <Link to="/network" style={styles.labels}>
            Explorer
          </Link>

          <Link to="/stats" style={styles.labels}>
            Stats
          </Link>
          <br />
          <br />
          <br />
          <br />
          {/* <Link to="/privacy" style={styles.link}>
            Privacy
          </Link>
          &nbsp; &nbsp; &nbsp;
          <Link to="/terms" style={styles.link}>
            Terms of Service
          </Link> */}

          <span className="light" style={styles.link}>
            &copy; 2023 urbitex | info@urbitex.io
          </span>
        </div>
      </div>
    </footer>
  );
};

const styles = {
  link: {
    fontSize: 13,
    color: "gray",
    textDecoration: "none",
    fontFamily: "Inter",
    fontWeight: 400,
  },
  storeImage: {
    padding: "3px",
  },
  labels: {
    color: "gray",
    fontFamily: "Inter",
    fontWeight: 400,
    textDecoration: "none",
    padding: 15,
  },
};

export default Footer;
