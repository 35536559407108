import { sigil, reactRenderer } from "@tlon/sigil-js";

const Sigil = (props) => {
  const { patp, size, colors } = props;

  const _sigil = sigil({
    patp: patp,
    renderer: reactRenderer,
    size: size,
    colors: colors,
  });

  return _sigil;
};

export default Sigil;
