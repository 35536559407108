import React, { useState, useEffect } from "react";
import Navbar from "../components/NavBar";
import TopBar from "../components/TopBar";

const FallBackScreen = () => {
  const [spin, setSpin] = useState();

  useEffect(() => {
    setTimeout(() => {
      setSpin(true);
    }, 1000);
  }, []);

  useEffect(() => {
    return () => {
      setSpin(false);
      // cleanup on unmount
    };
  }, []);

  return (
    <>
      <Navbar />
      <TopBar spin={spin} />
    </>
  );
};

export default FallBackScreen;
