import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import SearchBar from "./SearchBar";
import ob from "urbit-ob";
import { useHistory } from "react-router";
import * as utils from "../utils";
import WalletModal from "./WalletModal";
import * as c from "../constants";
import Web3, { ens } from "web3";
import ChainModal from "./ChainModal";
import AdminWarning from "./AdminWarning";
import logo from "../assets/img/urbitex_logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import ContactModal from "./ContactModal";
import detectEthereumProvider from "@metamask/detect-provider";
import { isMobile } from "react-device-detect";

const NavBar = () => {
  const history = useHistory();

  const [modalVisible, setModalVisible] = useState(false);
  const [metamaskExists, setMetamaskExists] = useState();
  const [showChainModal, setShowChainModal] = useState(false);
  const [infoModalVisible, setInfoModalVisible] = useState();
  const [ethProvider, setEthProvider] = useState();

  useEffect(() => {
    const asyncFunction = async () => {
      const provider = await detectEthereumProvider();
      setEthProvider(provider);
    };
    asyncFunction();
  }, []);

  const handleSearch = async (val) => {
    if (utils.isValidEthAddress(val)) {
      history.push(`/address/${val}`, {
        address: { address: val },
      });
      return null;
    }

    let point;
    if (typeof val === "number") {
      point = val;
    } else {
      point = ob.patp2dec(val);
    }

    const shipObj = await utils.constructShipObjectsArray([point]);
    history.push(`/ship/${shipObj[0].patp}`, { ship: shipObj[0] });
  };

  const toggleChainModal = () => {
    setShowChainModal(!showChainModal);
  };

  const toggleInfoModal = () => {
    setInfoModalVisible(!infoModalVisible);
  };

  const toggleModal = (mState) => {
    setModalVisible(mState);
    // setMetamaskExists(walletExists);
  };

  const handleWallet = async () => {
    let web3;
    const provider = ethProvider;

    if (isMobile) {
      if (provider) {
        const accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
        });

        if (accounts[0]) {
          const mainChain = await utils.isMainChain();
          if (!mainChain) {
            setShowChainModal(true);
            return;
          } else {
            history.push("/wallet");
          }
          return;
        }
      } else {
        toggleModal(true);
      }
    } else {
      // browser
      if (provider) {
        web3 = new Web3(provider);
      } else if (window.web3) {
        web3 = new Web3(window.web3.currentProvider);
      }

      // Check if User is already connected by retrieving the accounts
      const isWalletConnected = web3 && (await web3.eth.getAccounts());

      if (isWalletConnected?.length > 0) {
        const mainChain = await utils.isMainChain();
        if (!mainChain) {
          setShowChainModal(true);
          return;
        }

        history.push("/wallet");
      } else {
        toggleModal(true);
        setMetamaskExists(web3);
      }
    }
  };

  return (
    <Navbar
      bg="white"
      expand="lg"
      style={{
        // minHeight: 70,

        fontFamily: "Open Sans",
        fontSize: 17,
        fontWeight: 500,
      }}
    >
      <WalletModal
        walletExists={metamaskExists}
        toggleModal={toggleModal}
        modalState={modalVisible}
      />
      <ChainModal
        toggleChainModal={toggleChainModal}
        modalState={showChainModal}
        message={"Please set Metamask to the main Ethereum chain to continue."}
      />
      <ContactModal
        toggleModal={toggleInfoModal}
        modalState={infoModalVisible}
      />
      <Container style={{ minHeight: 60 }}>
        <Navbar.Brand>
          <Link className="navbar-brand mb-1 mr-5" to="/home">
            <img src={logo} style={{ width: 75 }} />
          </Link>
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="basic-navbar-nav" />

        <Navbar.Collapse id="basic-navbar-nav ">
          <Nav className="me-auto">
            <Nav.Item className="nav-link mx-3 my-2 d-flex">
              <SearchBar
                handleSubmit={handleSearch}
                width={300}
                height={40}
                placeholder={""}
              />
            </Nav.Item>
            {/* <Nav.Item className="nav-link mx-3 my-2 d-flex">
              <Link
                to="/swap"
                // className="text-dark nav-link-ltr nounderline pr-3 "
                className="text-dark nav-link nav-link-ltr"
              >
                Swap
              </Link>
            </Nav.Item> */}

            <Nav.Item className="nav-link mx-3 my-2 d-flex">
              <Link
                to="/market"
                // className="text-dark nav-link-ltr nounderline pr-3 "
                className="text-dark nav-link nav-link-ltr"
              >
                Marketplace
              </Link>
            </Nav.Item>

            <Nav.Item className="nav-link mx-3 my-2 d-flex">
              <Link to="/network" className="text-dark nav-link nav-link-ltr">
                Explorer
              </Link>
            </Nav.Item>

            <Nav.Item className="nav-link mx-3 my-2 d-flex">
              <Link to="/stats" className="text-dark nav-link nav-link-ltr">
                Stats
              </Link>
            </Nav.Item>
          </Nav>

          <Nav>
            <Nav.Item
              onClick={handleWallet}
              className="nav-link mx-3 my-2 d-flex"
              style={{ cursor: "pointer" }}
            >
              <div className="text-dark nav-link nav-link-ltr">Wallet</div>
            </Nav.Item>
          </Nav>
          {/* <div style={{ cursor: "pointer" }} onClick={() => toggleInfoModal()}>
            <FontAwesomeIcon
              icon={faInfoCircle}
              style={{
                fontSize: 18,
                color: "black",
                marginLeft: 25,
                marginRight: 10,
              }}
            />
          </div> */}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavBar;
