export const INFURA_ID = process.env.REACT_APP_INFURA_API;

export const ETHEREUM_CHAIN = "mainnet";
// const ETHEREUM_CHAIN = "goerli";

export const INFURA_URL = `https://${ETHEREUM_CHAIN}.infura.io/v3/${INFURA_ID}`;

// export const API_URL = "http://localhost:8000";
// export const API_URL = "http://192.168.1.21:8000";
export const API_URL = "https://urbitex.io:8000";
