import ob from "urbit-ob";

const prefix_thumb_left = [
  "sam",
  "mod",
  "dor",
  "lor",
  "hod",
  "sil",
  "tor",
  "dot",
  "dil",
  "for",
  "pil",
  "ram",
  "ril",
  "pod",
  "mot",
  "tam",
  "sav",
  "mor",
  "wor",
  "nor",
  "bot",
  "mag",
  "dav",
  "tag",
  "ran",
  "nod",
  "fig",
  "pag",
  "bil",
  "rip",
  "tod",
  "til",
  "fan",
  "lod",
  "bor",
  "hil",
  "dig",
  "fil",
  "mig",
  "rag",
  "lag",
  "nil",
  "mil",
  "fam",
  "fot",
  "hoc",
  "lan",
  "dan",
  "nam",
  "fod",
  "noc",
  "sor",
  "fip",
];

const prefix_thumb_right = []; // none
const suffix_thumb_left = []; // none
const suffix_thumb_right = [
  "sev",
  "syt",
  "nup",
  "lup",
  "lug",
  "ryt",
  "nex",
  "dex",
  "mev",
  "hex",
  "sup",
  "tev",
  "rex",
  "wex",
  "tug",
  "tex",
  "wyt",
  "lyt",
  "rev",
  "bex",
  "fer",
  "mer",
  "mex",
  "ber",
  "mug",
  "dev",
  "run",
  "pex",
  "dyt",
  "byt",
  "lev",
  "fex",
  "ner",
  "lex",
  "rup",
  "dun",
  "ler",
  "sug",
  "nyt",
  "nev",
];

const prefix_feather_left = [
  "lit",
  "dal",
  "roc",
  "loc",
  "sit",
  "rit",
  "bal",
  "mal",
  "sal",
  "pal",
  "ral",
  "moc",
  "poc",
  "bit",
  "rac",
  "toc",
  "mit",
  "fit",
  "nal",
  "tal",
  "pit",
];

const prefix_feather_right = ["wit", "soc", "doc", "fal", "hal", "wal"];

const suffix_feather_right = [
  "dyr",
  "tyv",
  "del",
  "sel",
  "syr",
  "bel",
  "myr",
  "pel",
  "fel",
  "tyr",
  "mel",
  "wel",
  "rel",
  "nyr",
  "lyr",
  "byr",
  "fyr",
  "tel",
  "nel",
];

const suffix_feather_left = [
  "syd",
  "wyd",
  "sef",
  "tyd",
  "ref",
  "def",
  "ryd",
  "lyd",
  "nyd",
];

const prefix_thumb_top = ["tim", "tob", "sim", "hob", "nim"];

const suffix_thumb_top = ["fun", "tun"];

const prefix_thumb_bottom = []; // none

const suffix_thumb_bottom = ["per", "dur", "ser", "der", "ter", "wer"];

const prefix_square = [
  "bin",
  "rin",
  "pas",
  "rov",
  "liv",
  "win",
  "das",
  "nov",
  "min",
  "som",
  "fin",
  "sip",
  "tas",
  "siv",
  "div",
  "las",
  "nom",
  "tin",
  "pin",
  "lom",
  "has",
  "lin",
  "tom",
  "fas",
  "hin",
  "mas",
  "din",
  "dov",
  "riv",
  "sov",
];

const suffix_square = [
  "sut",
  "pen",
  "heb",
  "peg",
  "put",
  "lut",
  "wen",
  "feb",
  "rut",
  "reb",
  "den",
  "nut",
  "reg",
  "meg",
  "web",
  "mut",
  "teb",
  "ben",
  "meb",
  "dut",
  "deg",
  "ren",
  "neb",
  "seg",
  "leb",
  "leg",
  "ten",
  "deb",
  "hut",
  "len",
  "fen",
  "seb",
  "sen",
  "weg",
  "teg",
];

const prefix_circle = [
  "doz",
  "mar",
  "sig",
  "sib",
  "rig",
  "sol",
  "dop",
  "hop",
  "dar",
  "fol",
  "hol",
  "lib",
  "bol",
  "lop",
  "nar",
  "mol",
  "rop",
  "tol",
  "nop",
  "dol",
  "lig",
  "tar",
  "wol",
  "rol",
  "nib",
  "sop",
  "hap",
  "tap",
  "dib",
  "bar",
  "par",
  "har",
  "top",
  "mop",
  "fop",
  "nol",
  "rib",
  "lar",
  "sar",
  "pol",
];

const suffix_circle = [
  "zod",
  "let",
  "ful",
  "sun",
  "lun",
  "sul",
  "ped",
  "tem",
  "led",
  "tul",
  "met",
  "dul",
  "het",
  "pet",
  "rul",
  "sem",
  "net",
  "mul",
  "wet",
  "det",
  "bet",
  "set",
  "med",
  "pun",
  "red",
  "ted",
  "nem",
  "pem",
  "wed",
  "nul",
  "ned",
  "fet",
  "ret",
  "hul",
  "rem",
  "dem",
  "fed",
  "sed",
  "mun",
];

const prefix_triangle_top_left = [
  "don",
  "pon",
  "lon",
  "ton",
  "lab",
  "ris",
  "mip",
  "mon",
  "mog",
  "ron",
  "tip",
  "nap",
  "tog",
  "fog",
  "sog",
  "mis",
  "dis",
  "lad",
  "wic",
  "lav",
  "rab",
  "pon",
  "son",
  "bon",
  "fon",
  "nis",
  "map",
];

const suffix_triangle_top_right = [
  "nec",
  "bud",
  "wyl",
  "dys",
  "hec",
  "lur",
  "pyc",
  "pyl",
  "tyl",
  "bes",
  "wyc",
  "nep",
  "rys",
  "sub",
  "rec",
  "sec",
  "fus",
  "hep",
  "mus",
  "ruc",
  "dec",
  "dyl",
  "mes",
  "tux",
  "sur",
  "tud",
  "nux",
  "rux",
  "nub",
  "dus",
  "mec",
  "rus",
  "num",
  "fep",
  "tus",
  "tyc",
  "lus",
  "nus",
  "tec",
  "pub",
  "tuc",
  "sud",
  "bus",
  "duc",
  "luc",
  "lec",
  "hus",
  "rud",
  "lud",
  "lys",
  "nys",
  "ryc",
  "nyl",
  "bec",
  "mud",
  "mur",
  "pec",
];

const prefix_triangle_bottom_left = [
  "wan",
  "hid",
  "fid",
  "dir",
  "wac",
  "sab",
  "wis",
  "lid",
  "mir",
  "lac",
  "sat",
  "tab",
  "tic",
  "pid",
  "los",
  "tir",
  "tad",
  "bic",
  "dif",
  "wid",
  "bis",
  "mid",
  "dap",
  "san",
  "nid",
  "sic",
  "nat",
  "pan",
  "pos",
  "ban",
  "wat",
  "bid",
  "pad",
  "dac",
  "tan",
  "sid",
  "fab",
  "lat",
  "nav",
  "rid",
  "pac",
  "rav",
  "pat",
  "tac",
  "fir",
  "bos",
  "bat",
  "hac",
  "tid",
  "hav",
  "sap",
  "hos",
  "dab",
  "dos",
  "mac",
  "hab",
  "nos",
  "dat",
  "hat",
  "nac",
  "rap",
  "mos",
  "bac",
  "lap",
  "ros",
  "mat",
];

const suffix_triangle_bottom_right = [
  "ryp",
  "syx",
  "byn",
  "bur",
  "pur",
  "syn",
  "wyn",
  "nym",
  "sum",
  "nyx",
  "wyx",
  "sym",
  "myn",
  "syp",
  "rum",
  "tyn",
  "lyx",
  "dux",
  "ryn",
  "pyx",
  "ryg",
  "ryx",
  "syl",
  "rym",
  "fyl",
  "byl",
  "typ",
  "myl",
  "fur",
  "fyn",
  "lyn",
  "dyn",
  "lux",
];

const prefix_triangle_bottom_right = ["han", "fos", "had", "fad"];

const suffix_triangle_bottom_left = ["ryl"];

const suffix_triangle_top_left = [
  "wes",
  "wep",
  "pes",
  "sep",
  "mep",
  "dep",
  "tep",
  "res",
  "bep",
  "lep",
  "des",
  "hes",
  "nes",
  "tes",
  "rep",
  "fes",
];

const prefix_triangle_top_right = ["lis", "pic", "mic", "ric", "rad"];

export const shapeChecker = (start, stop, patp, shapeList) => {
  var flag = false;
  let i;
  for (i = 0; i < shapeList.length; i++) {
    if (patp.substring(start, stop).includes(shapeList[i])) {
      flag = true;
      return flag;
    }
  }
};

export const sigilFullCircle = (patp) => {
  const result =
    shapeChecker(1, 4, patp, prefix_triangle_top_left) &&
    shapeChecker(4, 7, patp, suffix_triangle_top_right) &&
    shapeChecker(8, 11, patp, prefix_triangle_bottom_left) &&
    shapeChecker(11, 14, patp, suffix_triangle_bottom_right);

  return result;
};

export const sigilAllCircles = (patp) => {
  const result =
    shapeChecker(1, 4, patp, prefix_circle) &&
    shapeChecker(4, 7, patp, suffix_circle) &&
    shapeChecker(8, 11, patp, prefix_circle) &&
    shapeChecker(11, 14, patp, suffix_circle);

  return result;
};

export const sigilHappyFace = (patp) => {
  const result =
    shapeChecker(1, 4, patp, prefix_circle) &&
    shapeChecker(4, 7, patp, suffix_circle) &&
    shapeChecker(8, 11, patp, prefix_triangle_bottom_left) &&
    shapeChecker(11, 14, patp, suffix_triangle_bottom_right);

  return result;
};

export const sigilEvilFace = (patp) => {
  const result =
    shapeChecker(1, 4, patp, prefix_feather_left) &&
    shapeChecker(4, 7, patp, suffix_feather_right);

  return result;
};

export const sigilSadFace = (patp) => {
  const result =
    shapeChecker(1, 4, patp, prefix_circle) &&
    shapeChecker(4, 7, patp, suffix_circle) &&
    shapeChecker(8, 11, patp, prefix_triangle_top_left) &&
    shapeChecker(11, 14, patp, suffix_triangle_top_right);

  return result;
};

export const sigilFullSquare = (patp) => {
  const result =
    shapeChecker(1, 4, patp, prefix_square) &&
    shapeChecker(4, 7, patp, suffix_square) &&
    shapeChecker(8, 11, patp, prefix_square) &&
    shapeChecker(11, 14, patp, suffix_square);

  return result;
};

export const sigilHollowCircle = (patp) => {
  const result =
    shapeChecker(1, 4, patp, prefix_feather_right) &&
    shapeChecker(4, 7, patp, suffix_feather_left) &&
    shapeChecker(8, 11, patp, prefix_feather_left) &&
    shapeChecker(11, 14, patp, suffix_feather_right);

  return result;
};

export const sigilHalfMoons = (patp) => {
  const result =
    (shapeChecker(1, 4, patp, prefix_triangle_top_right) &&
      shapeChecker(4, 7, patp, suffix_triangle_top_right) &&
      shapeChecker(8, 11, patp, prefix_triangle_bottom_right) &&
      shapeChecker(11, 14, patp, suffix_triangle_bottom_right)) ||
    (shapeChecker(1, 4, patp, prefix_triangle_top_left) &&
      shapeChecker(4, 7, patp, suffix_triangle_top_left) &&
      shapeChecker(8, 11, patp, prefix_triangle_bottom_left) &&
      shapeChecker(11, 14, patp, suffix_triangle_bottom_left));

  return result;
};

export const sigilAllWings = (patp) => {
  const result =
    shapeChecker(1, 4, patp, prefix_feather_left) &&
    shapeChecker(4, 7, patp, suffix_feather_right) &&
    shapeChecker(8, 11, patp, prefix_feather_right) &&
    shapeChecker(11, 14, patp, suffix_feather_left);

  return result;
};

export const sigilShield = (patp) => {
  const result =
    shapeChecker(1, 4, patp, prefix_square) &&
    shapeChecker(4, 7, patp, suffix_square) &&
    shapeChecker(8, 11, patp, prefix_triangle_bottom_left) &&
    shapeChecker(11, 14, patp, suffix_triangle_bottom_right);

  return result;
};

export const shapeCode = (patp) => {
  if (patp.length != 14) return;

  const pre1 = patp.substring(1, 4);
  const suf1 = patp.substring(4, 7);
  const pre2 = patp.substring(8, 11);
  const suf2 = patp.substring(11, 14);

  const code1 = prefixes.find((p) => p.syl == pre1).code;
  const code2 = suffixes.find((p) => p.syl == suf1).code;
  const code3 = prefixes.find((p) => p.syl == pre2).code;
  const code4 = suffixes.find((p) => p.syl == suf2).code;

  const res =
    getCodeProbability(code1, "pre") *
    getCodeProbability(code2, "suf") *
    getCodeProbability(code3, "pre") *
    getCodeProbability(code4, "suf");

  // return `${code1}${code2}${code3}${code4}`;

  return (res * 4294901760) / 65280;
};

export const getChildrenWithRarity = (_pnt) => {
  const pnt = parseInt(_pnt);
  let shipArr = [];

  for (let i = 1; i < 65535; i++) {
    const pp = ob.patp(pnt + 65536 * i);
    shipArr.push({
      point: pnt + 65536 * i,
      patp: pp,
      rareness: shapeCode(pp),
    });
  }
  return shipArr;
};

const prefixes = [
  { syl: "sam", code: "a" },
  { syl: "mod", code: "a" },
  { syl: "dor", code: "a" },
  { syl: "lor", code: "a" },
  { syl: "hod", code: "a" },
  { syl: "sil", code: "a" },
  { syl: "tor", code: "a" },
  { syl: "dot", code: "a" },
  { syl: "dil", code: "a" },
  { syl: "for", code: "a" },
  { syl: "pil", code: "a" },
  { syl: "ram", code: "a" },
  { syl: "ril", code: "a" },
  { syl: "pod", code: "a" },
  { syl: "mot", code: "a" },
  { syl: "tam", code: "a" },
  { syl: "sav", code: "a" },
  { syl: "mor", code: "a" },
  { syl: "wor", code: "a" },
  { syl: "nor", code: "a" },
  { syl: "bot", code: "a" },
  { syl: "mag", code: "a" },
  { syl: "dav", code: "a" },
  { syl: "tag", code: "a" },
  { syl: "ran", code: "a" },
  { syl: "nod", code: "a" },
  { syl: "fig", code: "a" },
  { syl: "pag", code: "a" },
  { syl: "bil", code: "a" },
  { syl: "rip", code: "a" },
  { syl: "tod", code: "a" },
  { syl: "til", code: "a" },
  { syl: "fan", code: "a" },
  { syl: "lod", code: "a" },
  { syl: "bor", code: "a" },
  { syl: "hil", code: "a" },
  { syl: "dig", code: "a" },
  { syl: "fil", code: "a" },
  { syl: "mig", code: "a" },
  { syl: "rag", code: "a" },
  { syl: "lag", code: "a" },
  { syl: "nil", code: "a" },
  { syl: "mil", code: "a" },
  { syl: "fam", code: "a" },
  { syl: "fot", code: "a" },
  { syl: "hoc", code: "a" },
  { syl: "lan", code: "a" },
  { syl: "dan", code: "a" },
  { syl: "nam", code: "a" },
  { syl: "fod", code: "a" },
  { syl: "noc", code: "a" },
  { syl: "sor", code: "a" },
  { syl: "fip", code: "a" },
  { syl: "lit", code: "e" },
  { syl: "dal", code: "e" },
  { syl: "roc", code: "e" },
  { syl: "loc", code: "e" },
  { syl: "sit", code: "e" },
  { syl: "rit", code: "e" },
  { syl: "bal", code: "e" },
  { syl: "mal", code: "e" },
  { syl: "sal", code: "e" },
  { syl: "pal", code: "e" },
  { syl: "ral", code: "e" },
  { syl: "moc", code: "e" },
  { syl: "poc", code: "e" },
  { syl: "bit", code: "e" },
  { syl: "rac", code: "e" },
  { syl: "toc", code: "e" },
  { syl: "mit", code: "e" },
  { syl: "fit", code: "e" },
  { syl: "nal", code: "e" },
  { syl: "tal", code: "e" },
  { syl: "pit", code: "e" },
  { syl: "wit", code: "f" },
  { syl: "soc", code: "f" },
  { syl: "doc", code: "f" },
  { syl: "fal", code: "f" },
  { syl: "hal", code: "f" },
  { syl: "wal", code: "f" },
  { syl: "tim", code: "c" },
  { syl: "tob", code: "c" },
  { syl: "sim", code: "c" },
  { syl: "hob", code: "c" },
  { syl: "nim", code: "c" },
  { syl: "bin", code: "g" },
  { syl: "rin", code: "g" },
  { syl: "pas", code: "g" },
  { syl: "rov", code: "g" },
  { syl: "liv", code: "g" },
  { syl: "win", code: "g" },
  { syl: "das", code: "g" },
  { syl: "nov", code: "g" },
  { syl: "min", code: "g" },
  { syl: "som", code: "g" },
  { syl: "fin", code: "g" },
  { syl: "sip", code: "g" },
  { syl: "tas", code: "g" },
  { syl: "siv", code: "g" },
  { syl: "div", code: "g" },
  { syl: "las", code: "g" },
  { syl: "nom", code: "g" },
  { syl: "tin", code: "g" },
  { syl: "pin", code: "g" },
  { syl: "lom", code: "g" },
  { syl: "has", code: "g" },
  { syl: "lin", code: "g" },
  { syl: "tom", code: "g" },
  { syl: "fas", code: "g" },
  { syl: "hin", code: "g" },
  { syl: "mas", code: "g" },
  { syl: "din", code: "g" },
  { syl: "dov", code: "g" },
  { syl: "riv", code: "g" },
  { syl: "sov", code: "g" },
  { syl: "doz", code: "h" },
  { syl: "mar", code: "h" },
  { syl: "sig", code: "h" },
  { syl: "sib", code: "h" },
  { syl: "rig", code: "h" },
  { syl: "sol", code: "h" },
  { syl: "dop", code: "h" },
  { syl: "hop", code: "h" },
  { syl: "dar", code: "h" },
  { syl: "fol", code: "h" },
  { syl: "hol", code: "h" },
  { syl: "lib", code: "h" },
  { syl: "bol", code: "h" },
  { syl: "lop", code: "h" },
  { syl: "nar", code: "h" },
  { syl: "mol", code: "h" },
  { syl: "rop", code: "h" },
  { syl: "tol", code: "h" },
  { syl: "nop", code: "h" },
  { syl: "dol", code: "h" },
  { syl: "lig", code: "h" },
  { syl: "tar", code: "h" },
  { syl: "wol", code: "h" },
  { syl: "rol", code: "h" },
  { syl: "nib", code: "h" },
  { syl: "sop", code: "h" },
  { syl: "hap", code: "h" },
  { syl: "tap", code: "h" },
  { syl: "dib", code: "h" },
  { syl: "bar", code: "h" },
  { syl: "par", code: "h" },
  { syl: "har", code: "h" },
  { syl: "top", code: "h" },
  { syl: "mop", code: "h" },
  { syl: "fop", code: "h" },
  { syl: "nol", code: "h" },
  { syl: "rib", code: "h" },
  { syl: "lar", code: "h" },
  { syl: "sar", code: "h" },
  { syl: "pol", code: "h" },
  { syl: "pon", code: "i" },
  { syl: "lon", code: "i" },
  { syl: "ton", code: "i" },
  { syl: "lab", code: "i" },
  { syl: "ris", code: "i" },
  { syl: "mip", code: "i" },
  { syl: "mon", code: "i" },
  { syl: "mog", code: "i" },
  { syl: "ron", code: "i" },
  { syl: "tip", code: "i" },
  { syl: "nap", code: "i" },
  { syl: "tog", code: "i" },
  { syl: "fog", code: "i" },
  { syl: "sog", code: "i" },
  { syl: "mis", code: "i" },
  { syl: "dis", code: "i" },
  { syl: "lad", code: "i" },
  { syl: "don", code: "i" },
  { syl: "wic", code: "i" },
  { syl: "lav", code: "i" },
  { syl: "rab", code: "i" },
  { syl: "pon", code: "i" },
  { syl: "son", code: "i" },
  { syl: "bon", code: "i" },
  { syl: "fon", code: "i" },
  { syl: "nis", code: "i" },
  { syl: "map", code: "i" },
  { syl: "wan", code: "k" },
  { syl: "hid", code: "k" },
  { syl: "fid", code: "k" },
  { syl: "dir", code: "k" },
  { syl: "wac", code: "k" },
  { syl: "sab", code: "k" },
  { syl: "wis", code: "k" },
  { syl: "lid", code: "k" },
  { syl: "mir", code: "k" },
  { syl: "lac", code: "k" },
  { syl: "sat", code: "k" },
  { syl: "tab", code: "k" },
  { syl: "tic", code: "k" },
  { syl: "pid", code: "k" },
  { syl: "los", code: "k" },
  { syl: "tir", code: "k" },
  { syl: "tad", code: "k" },
  { syl: "bic", code: "k" },
  { syl: "dif", code: "k" },
  { syl: "wid", code: "k" },
  { syl: "bis", code: "k" },
  { syl: "mid", code: "k" },
  { syl: "dap", code: "k" },
  { syl: "san", code: "k" },
  { syl: "nid", code: "k" },
  { syl: "sic", code: "k" },
  { syl: "nat", code: "k" },
  { syl: "pan", code: "k" },
  { syl: "pos", code: "k" },
  { syl: "ban", code: "k" },
  { syl: "wat", code: "k" },
  { syl: "bid", code: "k" },
  { syl: "pad", code: "k" },
  { syl: "dac", code: "k" },
  { syl: "tan", code: "k" },
  { syl: "sid", code: "k" },
  { syl: "fab", code: "k" },
  { syl: "lat", code: "k" },
  { syl: "nav", code: "k" },
  { syl: "rid", code: "k" },
  { syl: "pac", code: "k" },
  { syl: "rav", code: "k" },
  { syl: "pat", code: "k" },
  { syl: "tac", code: "k" },
  { syl: "fir", code: "k" },
  { syl: "bos", code: "k" },
  { syl: "bat", code: "k" },
  { syl: "hac", code: "k" },
  { syl: "tid", code: "k" },
  { syl: "hav", code: "k" },
  { syl: "sap", code: "k" },
  { syl: "hos", code: "k" },
  { syl: "dab", code: "k" },
  { syl: "dos", code: "k" },
  { syl: "mac", code: "k" },
  { syl: "hab", code: "k" },
  { syl: "nos", code: "k" },
  { syl: "dat", code: "k" },
  { syl: "hat", code: "k" },
  { syl: "nac", code: "k" },
  { syl: "rap", code: "k" },
  { syl: "mos", code: "k" },
  { syl: "bac", code: "k" },
  { syl: "lap", code: "k" },
  { syl: "ros", code: "k" },
  { syl: "mat", code: "k" },
  { syl: "han", code: "l" },
  { syl: "fos", code: "l" },
  { syl: "had", code: "l" },
  { syl: "fad", code: "l" },
  { syl: "lis", code: "j" },
  { syl: "pic", code: "j" },
  { syl: "mic", code: "j" },
  { syl: "ric", code: "j" },
  { syl: "rad", code: "j" },
];

const suffixes = [
  { syl: "sev", code: "b" },
  { syl: "syt", code: "b" },
  { syl: "nup", code: "b" },
  { syl: "lup", code: "b" },
  { syl: "lug", code: "b" },
  { syl: "ryt", code: "b" },
  { syl: "nex", code: "b" },
  { syl: "dex", code: "b" },
  { syl: "mev", code: "b" },
  { syl: "hex", code: "b" },
  { syl: "sup", code: "b" },
  { syl: "tev", code: "b" },
  { syl: "rex", code: "b" },
  { syl: "wex", code: "b" },
  { syl: "tug", code: "b" },
  { syl: "tex", code: "b" },
  { syl: "wyt", code: "b" },
  { syl: "lyt", code: "b" },
  { syl: "rev", code: "b" },
  { syl: "bex", code: "b" },
  { syl: "fer", code: "b" },
  { syl: "mer", code: "b" },
  { syl: "mex", code: "b" },
  { syl: "ber", code: "b" },
  { syl: "mug", code: "b" },
  { syl: "dev", code: "b" },
  { syl: "run", code: "b" },
  { syl: "pex", code: "b" },
  { syl: "dyt", code: "b" },
  { syl: "byt", code: "b" },
  { syl: "lev", code: "b" },
  { syl: "fex", code: "b" },
  { syl: "ner", code: "b" },
  { syl: "lex", code: "b" },
  { syl: "rup", code: "b" },
  { syl: "dun", code: "b" },
  { syl: "ler", code: "b" },
  { syl: "sug", code: "b" },
  { syl: "nyt", code: "b" },
  { syl: "nev", code: "b" },
  { syl: "dyr", code: "f" },
  { syl: "tyv", code: "f" },
  { syl: "del", code: "f" },
  { syl: "sel", code: "f" },
  { syl: "syr", code: "f" },
  { syl: "bel", code: "f" },
  { syl: "myr", code: "f" },
  { syl: "pel", code: "f" },
  { syl: "fel", code: "f" },
  { syl: "tyr", code: "f" },
  { syl: "mel", code: "f" },
  { syl: "wel", code: "f" },
  { syl: "rel", code: "f" },
  { syl: "nyr", code: "f" },
  { syl: "lyr", code: "f" },
  { syl: "byr", code: "f" },
  { syl: "fyr", code: "f" },
  { syl: "tel", code: "f" },
  { syl: "nel", code: "f" },
  { syl: "syd", code: "e" },
  { syl: "wyd", code: "e" },
  { syl: "sef", code: "e" },
  { syl: "tyd", code: "e" },
  { syl: "ref", code: "e" },
  { syl: "def", code: "e" },
  { syl: "ryd", code: "e" },
  { syl: "lyd", code: "e" },
  { syl: "nyd", code: "e" },
  { syl: "fun", code: "c" },
  { syl: "tun", code: "c" },
  { syl: "per", code: "d" },
  { syl: "dur", code: "d" },
  { syl: "ser", code: "d" },
  { syl: "der", code: "d" },
  { syl: "ter", code: "d" },
  { syl: "wer", code: "d" },
  { syl: "sut", code: "g" },
  { syl: "pen", code: "g" },
  { syl: "heb", code: "g" },
  { syl: "peg", code: "g" },
  { syl: "put", code: "g" },
  { syl: "lut", code: "g" },
  { syl: "wen", code: "g" },
  { syl: "feb", code: "g" },
  { syl: "rut", code: "g" },
  { syl: "reb", code: "g" },
  { syl: "den", code: "g" },
  { syl: "nut", code: "g" },
  { syl: "reg", code: "g" },
  { syl: "meg", code: "g" },
  { syl: "web", code: "g" },
  { syl: "mut", code: "g" },
  { syl: "teb", code: "g" },
  { syl: "ben", code: "g" },
  { syl: "meb", code: "g" },
  { syl: "dut", code: "g" },
  { syl: "deg", code: "g" },
  { syl: "ren", code: "g" },
  { syl: "neb", code: "g" },
  { syl: "seg", code: "g" },
  { syl: "leb", code: "g" },
  { syl: "leg", code: "g" },
  { syl: "ten", code: "g" },
  { syl: "deb", code: "g" },
  { syl: "hut", code: "g" },
  { syl: "len", code: "g" },
  { syl: "fen", code: "g" },
  { syl: "seb", code: "g" },
  { syl: "sen", code: "g" },
  { syl: "weg", code: "g" },
  { syl: "teg", code: "g" },
  { syl: "zod", code: "h" },
  { syl: "let", code: "h" },
  { syl: "ful", code: "h" },
  { syl: "sun", code: "h" },
  { syl: "lun", code: "h" },
  { syl: "sul", code: "h" },
  { syl: "ped", code: "h" },
  { syl: "tem", code: "h" },
  { syl: "led", code: "h" },
  { syl: "tul", code: "h" },
  { syl: "met", code: "h" },
  { syl: "dul", code: "h" },
  { syl: "het", code: "h" },
  { syl: "pet", code: "h" },
  { syl: "rul", code: "h" },
  { syl: "sem", code: "h" },
  { syl: "net", code: "h" },
  { syl: "mul", code: "h" },
  { syl: "wet", code: "h" },
  { syl: "det", code: "h" },
  { syl: "bet", code: "h" },
  { syl: "set", code: "h" },
  { syl: "med", code: "h" },
  { syl: "pun", code: "h" },
  { syl: "red", code: "h" },
  { syl: "ted", code: "h" },
  { syl: "nem", code: "h" },
  { syl: "pem", code: "h" },
  { syl: "wed", code: "h" },
  { syl: "nul", code: "h" },
  { syl: "ned", code: "h" },
  { syl: "fet", code: "h" },
  { syl: "ret", code: "h" },
  { syl: "hul", code: "h" },
  { syl: "rem", code: "h" },
  { syl: "dem", code: "h" },
  { syl: "fed", code: "h" },
  { syl: "sed", code: "h" },
  { syl: "mun", code: "h" },
  { syl: "nec", code: "j" },
  { syl: "bud", code: "j" },
  { syl: "wyl", code: "j" },
  { syl: "dys", code: "j" },
  { syl: "hec", code: "j" },
  { syl: "pyc", code: "j" },
  { syl: "pyl", code: "j" },
  { syl: "tyl", code: "j" },
  { syl: "bes", code: "j" },
  { syl: "wyc", code: "j" },
  { syl: "nep", code: "j" },
  { syl: "rys", code: "j" },
  { syl: "sub", code: "j" },
  { syl: "rec", code: "j" },
  { syl: "sec", code: "j" },
  { syl: "fus", code: "j" },
  { syl: "hep", code: "j" },
  { syl: "mus", code: "j" },
  { syl: "ruc", code: "j" },
  { syl: "dec", code: "j" },
  { syl: "dyl", code: "j" },
  { syl: "mes", code: "j" },
  { syl: "tux", code: "j" },
  { syl: "sur", code: "j" },
  { syl: "tud", code: "j" },
  { syl: "nux", code: "j" },
  { syl: "rux", code: "j" },
  { syl: "nub", code: "j" },
  { syl: "dus", code: "j" },
  { syl: "mec", code: "j" },
  { syl: "rus", code: "j" },
  { syl: "num", code: "j" },
  { syl: "fep", code: "j" },
  { syl: "tus", code: "j" },
  { syl: "tyc", code: "j" },
  { syl: "lus", code: "j" },
  { syl: "nus", code: "j" },
  { syl: "tec", code: "j" },
  { syl: "pub", code: "j" },
  { syl: "tuc", code: "j" },
  { syl: "sud", code: "j" },
  { syl: "bus", code: "j" },
  { syl: "lur", code: "j" },
  { syl: "duc", code: "j" },
  { syl: "luc", code: "j" },
  { syl: "lec", code: "j" },
  { syl: "hus", code: "j" },
  { syl: "rud", code: "j" },
  { syl: "lud", code: "j" },
  { syl: "lys", code: "j" },
  { syl: "nys", code: "j" },
  { syl: "ryc", code: "j" },
  { syl: "nyl", code: "j" },
  { syl: "bec", code: "j" },
  { syl: "mud", code: "j" },
  { syl: "mur", code: "j" },
  { syl: "pec", code: "j" },
  { syl: "ryp", code: "l" },
  { syl: "syx", code: "l" },
  { syl: "byn", code: "l" },
  { syl: "bur", code: "l" },
  { syl: "pur", code: "l" },
  { syl: "syn", code: "l" },
  { syl: "wyn", code: "l" },
  { syl: "nym", code: "l" },
  { syl: "sum", code: "l" },
  { syl: "nyx", code: "l" },
  { syl: "wyx", code: "l" },
  { syl: "sym", code: "l" },
  { syl: "myn", code: "l" },
  { syl: "syp", code: "l" },
  { syl: "rum", code: "l" },
  { syl: "tyn", code: "l" },
  { syl: "lyx", code: "l" },
  { syl: "dux", code: "l" },
  { syl: "ryn", code: "l" },
  { syl: "pyx", code: "l" },
  { syl: "ryg", code: "l" },
  { syl: "ryx", code: "l" },
  { syl: "syl", code: "l" },
  { syl: "rym", code: "l" },
  { syl: "fyl", code: "l" },
  { syl: "byl", code: "l" },
  { syl: "typ", code: "l" },
  { syl: "myl", code: "l" },
  { syl: "fur", code: "l" },
  { syl: "fyn", code: "l" },
  { syl: "lyn", code: "l" },
  { syl: "dyn", code: "l" },
  { syl: "lux", code: "l" },
  { syl: "ryl", code: "k" },
  { syl: "wes", code: "i" },
  { syl: "wep", code: "i" },
  { syl: "pes", code: "i" },
  { syl: "sep", code: "i" },
  { syl: "mep", code: "i" },
  { syl: "dep", code: "i" },
  { syl: "tep", code: "i" },
  { syl: "res", code: "i" },
  { syl: "bep", code: "i" },
  { syl: "lep", code: "i" },
  { syl: "des", code: "i" },
  { syl: "hes", code: "i" },
  { syl: "nes", code: "i" },
  { syl: "tes", code: "i" },
  { syl: "rep", code: "i" },
  { syl: "fes", code: "i" },
];

const rareLookup = [
  { code: "h", pre: 0.15625, suf: 0.15234375 },
  { code: "g", pre: 0.1171875, suf: 0.13671875 },
  { code: "e", pre: 0.08203125, suf: 0.03515625 },
  { code: "f", pre: 0.0234375, suf: 0.07421875 },
  { code: "c", pre: 0.01953125, suf: 0.0078125 },
  { code: "d", pre: 0, suf: 0.0234375 },
  { code: "a", pre: 0.20703125, suf: 0 },
  { code: "b", pre: 0, suf: 0.15625 },
  { code: "i", pre: 0.1015625, suf: 0.0625 },
  { code: "j", pre: 0.01953125, suf: 0.21875 },
  { code: "k", pre: 0.2578125, suf: 0.00390625 },
  { code: "l", pre: 0.015625, suf: 0.12890625 },
];

export const getCodeProbability = (code, position) => {
  const result = rareLookup.find((c) => c.code == code)[position];

  return result;
};
