import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AppSwitch from "./components/AppSwitch";
import ErrorBoundary from "./components/ErrorBoundary";
import React, { useState, useEffect } from "react";
import MobileScreen from "./screens/MobileScreen";
import NoInternetConnection from "./screens/NoInternetConnection";

function App() {
  return (
    <>
      <ToastContainer
        autoClose={5000}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
      />
      <ErrorBoundary>
        <NoInternetConnection>
          <AppSwitch />
        </NoInternetConnection>
      </ErrorBoundary>
    </>
  );
}

export default App;
