import React, { useState, useEffect } from "react";
import metamask from "../assets/img/metamask-fox.svg";
import Modal from "react-modal";
import * as utils from "../utils";
import { useHistory } from "react-router";
import { Spinner } from "react-bootstrap";
import Web3 from "web3";
import * as c from "../constants";
import { toast } from "react-toastify";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import mmask from "../assets/img/mmask.png";
import { BrowserView, MobileView } from "react-device-detect";

const WalletModal = (props) => {
  const { walletExists } = props;
  const history = useHistory();

  const [metamaskStatus, setMetamaskStatus] = useState();

  useEffect(() => {
    window.ethereum &&
      window.ethereum.on("accountsChanged", function (accounts) {
        if (utils.isValidEthAddress(accounts[0])) {
          // history.push("/account");
          history.go(0);
          // setMetamaskStatus("CONNECTED");
          // toast(`You have successfully connected Metamask`, {
          //   className: `Toastify__toast-container  Toastify__toast--success`,
          //   hideProgressBar: true,
          // });
        }
      });
  }, []);

  const renderInitialContent = () => {
    return (
      <div style={{ padding: 20, backgroundColor: "white" }}>
        <div
          style={{
            fontFamily: "Inter",
            backgroundColor: "white",
            fontSize: 22,
            fontWeight: 800,
            marginLeft: 10,
          }}
        >
          Connect your Ethereum wallet.
        </div>

        {renderMessage()}

        <Container>
          <Row className="justify-content-md-center align-items-center">
            <Col style={{ border: "none", marginTop: 10 }}>
              <button
                className="btn btn-outline-dark btn-lg"
                style={{
                  height: 70,
                  borderRadius: 12,
                  border: "solid",
                  borderWidth: 0.1,
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                  borderColor: "rgb(0,30,100,0.3)",
                  // paddingLeft: 30,
                  fontFamily: "Inter",
                  fontWeight: 600,
                  fontSize: 18,
                  boxShadow: "1px 1px 10px 0px rgba(0,0,0,0.1)",
                  minWidth: 200,
                  width: "100%",

                  // marginBottom: 50,
                }}
                onClick={() => props.toggleModal(false)}
              >
                Cancel
              </button>
            </Col>
            <Col style={{ border: "none", marginTop: 10 }}>
              <button
                disabled={!walletExists}
                className="btn btn-primary"
                onClick={handleConnectWallet}
                style={{
                  height: 70,
                  borderRadius: 12,
                  border: "solid",
                  borderWidth: 0.1,
                  alignItems: "center",
                  display: "flex",
                  // borderColor: "rgb(0,30,100,0.3)",
                  // paddingLeft: 30,
                  boxShadow: "1px 1px 10px 0px rgba(0,0,0,0.1)",
                  // minWidth: 400,
                  textAlign: "center",
                  minWidth: 200,
                  justifyContent: "center",
                  // backgroundColor: "black",
                  cursor: "pointer",
                  width: "100%",
                  // marginBottom: 50,
                }}
              >
                {/* <img src={metamask} style={{ width: 45, marginRight: 5 }} /> */}
                <div
                  style={{
                    fontFamily: "Inter",
                    shadow: "none",
                    color: "white",
                    fontSize: 18,
                    fontWeight: 800,
                    textAlign: "center",
                    // paddingRight: 25,
                  }}
                >
                  Connect
                </div>
              </button>
            </Col>
          </Row>
        </Container>

        {/* <div
          style={{ display: "flex", marginTop: 70, alignItems: "center" }}
        ></div> */}
      </div>
    );
  };

  const renderMobileContent = () => {
    return (
      <div
        style={{
          padding: 20,
          backgroundColor: "white",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            fontFamily: "Inter",
            backgroundColor: "white",
            fontSize: 22,
            fontWeight: 800,
            // marginLeft: 10,
            maxWidth: 270,
          }}
        >
          Connect using the Metamask App browser
        </div>

        <div
          style={{
            fontFamily: "Inter",
            fontSize: 16,
            fontWeight: 600,
            color: "gray",
            paddingTop: 20,
            paddingBottom: 20,
            backgroundColor: "white",
            // marginLeft: 10,
          }}
        >
          <div>
            <a
              style={{ textDecoration: "none" }}
              target="_blank"
              href={"https://metamask.io/download"}
            >
              {" "}
              Download the app
            </a>{" "}
            here.
          </div>
        </div>

        <Container>
          <Row className="justify-content-md-center align-items-center">
            <Col style={{ border: "none", marginTop: 10, textAlign: "center" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <button
                  className="btn btn-outline-dark btn-lg"
                  style={{
                    height: 50,
                    borderRadius: 12,
                    border: "solid",
                    borderWidth: 0.1,
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                    borderColor: "rgb(0,30,100,0.3)",
                    // paddingLeft: 30,
                    fontFamily: "Inter",
                    fontWeight: 600,
                    fontSize: 18,
                    boxShadow: "1px 1px 10px 0px rgba(0,0,0,0.1)",
                    // minWidth: 200,
                    // width: "100%",
                    width: 120,

                    // marginBottom: 50,
                  }}
                  onClick={() => props.toggleModal(false)}
                >
                  Close
                </button>
              </div>
            </Col>
          </Row>
        </Container>

        {/* <div
          style={{ display: "flex", marginTop: 70, alignItems: "center" }}
        ></div> */}
      </div>
    );
  };

  const renderPendingContent = () => {
    return (
      <div
        style={{
          padding: 20,
          backgroundColor: "white",
          justifyContent: "center",
          display: "flex",
          flexDirection: "column",
          maxWidth: 360,
          // alignItems: "center",
        }}
      >
        <div
          style={{
            fontFamily: "Inter",
            backgroundColor: "white",
            fontSize: 22,
            fontWeight: 800,
            display: "flex",
            alignItems: "center",
            // justifyContent: "center",
          }}
        >
          Awaiting Metamask Login
          <div style={{ marginLeft: 20, fontSize: 19 }}>
            <Spinner
              as="span"
              animation="border"
              // animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          </div>
        </div>
        <div
          style={{
            fontFamily: "Inter",
            fontSize: 16,
            fontWeight: 600,
            color: "gray",
            // paddingTop: 20,
            marginBottom: 15,
            marginTop: 10,
            backgroundColor: "white",
            justifyContent: "flex-start",
          }}
        >
          Click the Metamask icon in your browser to sign in.
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            overflow: "hidden",
          }}
        >
          {/* <img
            src={mmask}
            style={{
              maxWidth: 300,
              marginRight: 10,
              justifyContent: "center",
              display: "flex",
              borderRadius: 12,
            }}
          /> */}
        </div>

        <div
          style={{
            display: "flex",
            marginTop: 30,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <button
            className="btn btn-outline-dark btn-lg"
            style={{
              height: 50,
              borderRadius: 12,
              border: "solid",
              borderWidth: 0.1,
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              borderColor: "rgb(0,30,100,0.3)",
              // paddingLeft: 30,
              fontFamily: "Inter",
              fontWeight: 600,
              fontSize: 18,
              boxShadow: "1px 1px 10px 0px rgba(0,0,0,0.1)",
              width: 120,
              // marginLeft: 15,
              // marginBottom: 50,
            }}
            onClick={() => props.toggleModal(false)}
          >
            Cancel
          </button>
        </div>
      </div>
    );
  };

  const handleConnectWallet = () => {
    const Web3 = require("web3");

    if (window.ethereum) {
      // await window.ethereum.send("eth_requestAccounts");

      setMetamaskStatus("PENDING");

      window.ethereum
        .request({
          method: "eth_requestAccounts",
        })
        .then((tx) => {
          window.web3 = new Web3(window.ethereum);
        })
        .catch((error) => {
          if (error.code == -32002) {
            setMetamaskStatus("PENDING");
          } else {
            setMetamaskStatus("NOT_CONNECTED");
          }
        });
    } else {
      //
    }
  };

  const renderMessage = () => {
    return (
      <div
        style={{
          fontFamily: "Inter",
          fontSize: 16,
          fontWeight: 600,
          color: "gray",
          paddingTop: 20,
          paddingBottom: 20,
          backgroundColor: "white",
          marginLeft: 10,
        }}
      >
        <>
          Only{" "}
          <a
            style={{ textDecoration: "none" }}
            target="_blank"
            href={"https://metamask.io/download"}
          >
            {" "}
            Metamask
          </a>{" "}
          is supported at this time.
        </>

        {/* {!walletExists && (
          <div>
            <a
              style={{ textDecoration: "none" }}
              target="_blank"
              href={"https://metamask.io/download"}
            >
              {" "}
              Install Metamask
            </a>{" "}
            to continue.
          </div>
        )} */}
      </div>
    );
  };

  return (
    <Modal
      ariaHideApp={false}
      isOpen={props.modalState}
      onAfterOpen={() => {}}
      onRequestClose={() => props.toggleModal(false)}
      style={styles.customStyles}
      contentLabel="Wallet"
    >
      <BrowserView>
        {metamaskStatus == "PENDING"
          ? renderPendingContent()
          : renderInitialContent()}
      </BrowserView>
      <MobileView>{renderMobileContent()}</MobileView>
    </Modal>
  );
};

const styles = {
  customStyles: {
    backgroundColor: "white",
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      borderRadius: 15,
      boxShadow: "1px 1px 10px 1px rgba(0,0,0,0.1)",
      // height: 360,
      // width: 700,
      zIndex: 100,
      paddingLeft: 15,
      paddingRight: 15,
      paddingTop: 10,
      backgroundColor: "white",
      padding: 0,
      // marginRight: 20,
      // marginLeft: 20,
      // paddingLeft: 20,
      // paddingRight: 20,
    },
  },
};

export default WalletModal;
