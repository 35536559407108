import * as c from "../constants";
import Web3 from "web3";
import * as abi from "../abi";
import * as utils from ".";
import { toast } from "react-toastify";

export const changeDelta = async (pairAddress, newDelta) => {
  const provider = new Web3.providers.HttpProvider(c.INFURA_URL);
  const web3 = new Web3(provider);

  const accounts = await window.ethereum.request({
    method: "eth_requestAccounts",
  });

  const abi = web3.eth.abi.encodeFunctionCall(
    {
      name: "changeDelta",
      type: "function",
      inputs: [
        {
          type: "uint128",
          name: "newDelta",
        },
      ],
    },
    [newDelta]
  );

  const params = {
    from: accounts[0],
    to: pairAddress,
    value: 0,
    data: abi,
    // gasLimit: 80000,
  };

  await window.ethereum.enable();

  const web3js = new Web3(window.ethereum);

  const result = await web3js.eth
    .sendTransaction(params)
    .on("transactionHash", (hash) => {
      // setTxnHash(hash);
    })
    .then(async (tx) => {
      // setTxnLoading(false);
      // setTxnHash();

      toast(`Transaction complete!`, {
        className: `Toastify__toast-container  Toastify__toast--success`,
        hideProgressBar: true,
      });
      return true;
    })
    .catch((error) => {
      console.log("error");
      console.log(error);
      if (error.code == 4001) {
        toast(`You cancelled the transaction.`, {
          className: `Toastify__toast-container  Toastify__toast--warning`,
          hideProgressBar: true,
        });
      } else {
        toast(`Transaction not complete.`, {
          className: `Toastify__toast-container  Toastify__toast--warning`,
          hideProgressBar: true,
        });
      }
      return false;
    });
  return result;
};

export const changeFee = async (pairAddress, newFee) => {
  // if (newFee < 0 || newFee >= 90) return;

  const provider = new Web3.providers.HttpProvider(c.INFURA_URL);
  const web3 = new Web3(provider);

  const accounts = await window.ethereum.request({
    method: "eth_requestAccounts",
  });

  const abi = web3.eth.abi.encodeFunctionCall(
    {
      name: "changeFee",
      type: "function",
      inputs: [
        {
          type: "uint96",
          name: "newFee",
        },
      ],
    },
    [newFee]
  );

  const params = {
    from: accounts[0],
    to: pairAddress,
    value: 0,
    data: abi,
    // gasLimit: 80000,
  };

  await window.ethereum.enable();

  const web3js = new Web3(window.ethereum);

  const result = web3js.eth
    .sendTransaction(params)
    .on("transactionHash", (hash) => {
      // setTxnHash(hash);
    })
    .then((tx) => {
      // setTxnLoading(false);
      // setTxnHash();
      toast(`Transaction complete!`, {
        className: `Toastify__toast-container  Toastify__toast--success`,
        hideProgressBar: true,
      });
      return true;
    })
    .catch((error) => {
      console.log("error");
      console.log(error);
      if (error.code == 4001) {
        toast(`You cancelled the transaction.`, {
          className: `Toastify__toast-container  Toastify__toast--warning`,
          hideProgressBar: true,
        });
      } else {
        toast(`Transaction not complete.`, {
          className: `Toastify__toast-container  Toastify__toast--warning`,
          hideProgressBar: true,
        });
      }
      return false;
    });
  return result;
};

export const changeSpotPrice = async (pairAddress, newSpotPrice) => {
  const provider = new Web3.providers.HttpProvider(c.INFURA_URL);
  const web3 = new Web3(provider);

  const accounts = await window.ethereum.request({
    method: "eth_requestAccounts",
  });

  const abi = web3.eth.abi.encodeFunctionCall(
    {
      name: "changeSpotPrice",
      type: "function",
      inputs: [
        {
          type: "uint128",
          name: "newSpotPrice",
        },
      ],
    },
    [newSpotPrice]
  );

  const params = {
    from: accounts[0],
    to: pairAddress,
    value: 0,
    data: abi,
    // gasLimit: 80000,
  };

  await window.ethereum.enable();

  const web3js = new Web3(window.ethereum);

  const result = await web3js.eth
    .sendTransaction(params)
    .on("transactionHash", (hash) => {
      // setTxnHash(hash);
    })
    .then(async (tx) => {
      // setTxnLoading(false);
      // setTxnHash();
      toast(`Transaction complete!`, {
        className: `Toastify__toast-container  Toastify__toast--success`,
        hideProgressBar: true,
      });
      return true;
    })
    .catch((error) => {
      console.log("error");
      console.log(error);
      if (error.code == 4001) {
        toast(`You cancelled the transaction.`, {
          className: `Toastify__toast-container  Toastify__toast--warning`,
          hideProgressBar: true,
        });
      } else {
        toast(`Transaction not complete.`, {
          className: `Toastify__toast-container  Toastify__toast--warning`,
          hideProgressBar: true,
        });
      }
      return false;
    });
  return result;
};

export const changeAssetRecipient = async (pairAddress, newRecipient) => {
  const provider = new Web3.providers.HttpProvider(c.INFURA_URL);
  const web3 = new Web3(provider);

  const accounts = await window.ethereum.request({
    method: "eth_requestAccounts",
  });

  const abi = web3.eth.abi.encodeFunctionCall(
    {
      name: "changeAssetRecipient",
      type: "function",
      inputs: [
        {
          type: "address",
          name: "newRecipient",
        },
      ],
    },
    [newRecipient]
  );

  const params = {
    from: accounts[0],
    to: pairAddress,
    value: 0,
    data: abi,
    // gasLimit: 100000,
  };

  await window.ethereum.enable();

  const web3js = new Web3(window.ethereum);

  const result = await web3js.eth
    .sendTransaction(params)
    .on("transactionHash", (hash) => {
      // setTxnHash(hash);
    })
    .then(async (tx) => {
      // setTxnLoading(false);
      // setTxnHash();
      toast(`Transaction complete!`, {
        className: `Toastify__toast-container  Toastify__toast--success`,
        hideProgressBar: true,
      });
      return true;
    })
    .catch((error) => {
      console.log("error");
      console.log(error);
      if (error.code == 4001) {
        toast(`You cancelled the transaction.`, {
          className: `Toastify__toast-container  Toastify__toast--warning`,
          hideProgressBar: true,
        });
      } else {
        toast(`Transaction not complete.`, {
          className: `Toastify__toast-container  Toastify__toast--warning`,
          hideProgressBar: true,
        });
      }
      return false;
    });
  return result;
};

export const depositStars = async (pairAddress, stars) => {
  const provider = new Web3.providers.HttpProvider(c.INFURA_URL);
  const web3 = new Web3(provider);

  const accounts = await window.ethereum.request({
    method: "eth_requestAccounts",
  });

  const abi = web3.eth.abi.encodeFunctionCall(
    {
      name: "depositNFTs",
      type: "function",
      inputs: [
        {
          type: "uint256[]",
          name: "ids",
        },
        {
          type: "address",
          name: "recipient",
        },
      ],
    },
    [stars, pairAddress]
  );

  const params = {
    from: accounts[0],
    to: c.CONTRACT.LSSVMPairFactory,
    value: 0,
    data: abi,
    // gasLimit: 500000 * stars.length,
  };

  await window.ethereum.enable();

  const web3js = new Web3(window.ethereum);

  const result = await web3js.eth
    .sendTransaction(params)
    .on("transactionHash", (hash) => {
      // setTxnHash(hash);
    })
    .then(async (tx) => {
      // setTxnLoading(false);
      // setTxnHash();
      toast(`Transaction complete!`, {
        className: `Toastify__toast-container  Toastify__toast--success`,
        hideProgressBar: true,
      });
      return true;
    })
    .catch((error) => {
      console.log("error");
      console.log(error);
      if (error.code == 4001) {
        toast(`You cancelled the transaction.`, {
          className: `Toastify__toast-container  Toastify__toast--warning`,
          hideProgressBar: true,
        });
      } else {
        toast(`Transaction not complete.`, {
          className: `Toastify__toast-container  Toastify__toast--warning`,
          hideProgressBar: true,
        });
      }
      return false;
    });
  return result;
};

export const withdrawStars = async (pairAddress, stars) => {
  const provider = new Web3.providers.HttpProvider(c.INFURA_URL);
  const web3 = new Web3(provider);

  const accounts = await window.ethereum.request({
    method: "eth_requestAccounts",
  });

  const abi = web3.eth.abi.encodeFunctionCall(
    {
      name: "withdrawERC721",
      type: "function",
      inputs: [
        {
          internalType: "IERC721",
          type: "address",
          name: "a",
        },
        {
          type: "uint256[]",
          name: "nftIds",
        },
      ],
    },
    [c.CONTRACT.ecliptic, stars]
  );

  const params = {
    from: accounts[0],
    to: pairAddress,
    value: 0,
    data: abi,
    // gasLimit: 400000 * stars.length,
  };

  await window.ethereum.enable();

  const web3js = new Web3(window.ethereum);

  const result = await web3js.eth
    .sendTransaction(params)
    .on("transactionHash", (hash) => {
      // setTxnHash(hash);
    })
    .then(async (tx) => {
      // setTxnLoading(false);
      // setTxnHash();
      toast(`Transaction complete!`, {
        className: `Toastify__toast-container  Toastify__toast--success`,
        hideProgressBar: true,
      });
      return true;
    })
    .catch((error) => {
      console.log("error");
      console.log(error);
      if (error.code == 4001) {
        toast(`You cancelled the transaction.`, {
          className: `Toastify__toast-container  Toastify__toast--warning`,
          hideProgressBar: true,
        });
      } else {
        toast(`Transaction not complete.`, {
          className: `Toastify__toast-container  Toastify__toast--warning`,
          hideProgressBar: true,
        });
      }
      return false;
    });
  return result;
};

export const withdrawETH = async (pairAddress, amount) => {
  const provider = new Web3.providers.HttpProvider(c.INFURA_URL);
  const web3 = new Web3(provider);

  const accounts = await window.ethereum.request({
    method: "eth_requestAccounts",
  });

  const abi = web3.eth.abi.encodeFunctionCall(
    {
      name: "withdrawETH",
      type: "function",
      inputs: [
        {
          type: "uint256",
          name: "amount",
        },
      ],
    },
    [amount]
  );

  const params = {
    from: accounts[0],
    to: pairAddress,
    value: 0,
    data: abi,
    // gasLimit: 100000,
  };

  await window.ethereum.enable();

  const web3js = new Web3(window.ethereum);

  const result = await web3js.eth
    .sendTransaction(params)
    .on("transactionHash", (hash) => {
      // setTxnHash(hash);
    })
    .then(async (tx) => {
      // setTxnLoading(false);
      // setTxnHash();
      toast(`Transaction complete!`, {
        className: `Toastify__toast-container  Toastify__toast--success`,
        hideProgressBar: true,
      });
      return true;
    })
    .catch((error) => {
      console.log("error");
      console.log(error);
      if (error.code == 4001) {
        toast(`You cancelled the transaction.`, {
          className: `Toastify__toast-container  Toastify__toast--warning`,
          hideProgressBar: true,
        });
      } else {
        toast(`Transaction not complete.`, {
          className: `Toastify__toast-container  Toastify__toast--warning`,
          hideProgressBar: true,
        });
      }
      return false;
    });
  return result;
};

export const depositETH = async (pairAddress, amount) => {
  const provider = new Web3.providers.HttpProvider(c.INFURA_URL);
  const web3 = new Web3(provider);

  const accounts = await window.ethereum.request({
    method: "eth_requestAccounts",
  });

  const params = {
    from: accounts[0],
    to: pairAddress,
    value: amount,
    data: null,
    // gasLimit: 100000,
  };

  await window.ethereum.enable();

  const web3js = new Web3(window.ethereum);

  const result = await web3js.eth
    .sendTransaction(params)
    .on("transactionHash", (hash) => {
      // setTxnHash(hash);
    })
    .then(async (tx) => {
      // setTxnLoading(false);
      // setTxnHash();
      toast(`Transaction complete!`, {
        className: `Toastify__toast-container  Toastify__toast--success`,
        hideProgressBar: true,
      });
      return true;
    })
    .catch((error) => {
      console.log("error");
      console.log(error);
      if (error.code == 4001) {
        toast(`You cancelled the transaction.`, {
          className: `Toastify__toast-container  Toastify__toast--warning`,
          hideProgressBar: true,
        });
      } else {
        toast(`Transaction not complete.`, {
          className: `Toastify__toast-container  Toastify__toast--warning`,
          hideProgressBar: true,
        });
      }
      return false;
    });
  return result;
};

export const withdrawAllETH = async (pairAddress) => {
  const provider = new Web3.providers.HttpProvider(c.INFURA_URL);
  const web3 = new Web3(provider);

  const accounts = await window.ethereum.request({
    method: "eth_requestAccounts",
  });

  const abi = web3.eth.abi.encodeFunctionCall(
    {
      name: "withdrawAllETH",
      type: "function",
      inputs: [],
    },
    []
  );

  const params = {
    from: accounts[0],
    to: pairAddress,
    value: 0,
    data: abi,
    // gasLimit: 50000,
  };

  await window.ethereum.enable();

  const web3js = new Web3(window.ethereum);

  const result = await web3js.eth
    .sendTransaction(params)
    .on("transactionHash", (hash) => {
      // setTxnHash(hash);
    })
    .then(async (tx) => {
      // setTxnLoading(false);
      // setTxnHash();
      toast(`Transaction complete!`, {
        className: `Toastify__toast-container  Toastify__toast--success`,
        hideProgressBar: true,
      });
      return true;
    })
    .catch((error) => {
      console.log("error");
      console.log(error);
      if (error.code == 4001) {
        toast(`You cancelled the transaction.`, {
          className: `Toastify__toast-container  Toastify__toast--warning`,
          hideProgressBar: true,
        });
      } else {
        toast(`Transaction not complete.`, {
          className: `Toastify__toast-container  Toastify__toast--warning`,
          hideProgressBar: true,
        });
      }
      return false;
    });
  return result;
};

export const approveRouter = async (pairAddress) => {
  const provider = new Web3.providers.HttpProvider(c.INFURA_URL);
  const web3 = new Web3(provider);

  const accounts = await window.ethereum.request({
    method: "eth_requestAccounts",
  });

  const bytesData = `0xa22cb465000000000000000000000000${c.CONTRACT.router.substring(
    2,
    500
  )}0000000000000000000000000000000000000000000000000000000000000001`;

  const abi = {
    name: "call",
    type: "function",
    inputs: [
      {
        type: "address",
        name: "target",
      },
      {
        type: "bytes",
        name: "data",
      },
    ],
  };

  const types = ["address", "bytes"];

  const params = [c.CONTRACT.ecliptic, bytesData];

  const callData =
    web3.eth.abi.encodeFunctionSignature(abi) +
    web3.eth.abi.encodeParameters(types, params).substring(2, 5000);

  await window.ethereum.enable();

  const web3js = new Web3(window.ethereum);

  const finalParams = {
    from: accounts[0],
    to: pairAddress,
    value: 0,
    data: callData,
    // gasLimit: 100000,
  };

  const result = await web3js.eth
    .sendTransaction(finalParams)
    .on("transactionHash", (hash) => {
      // setTxnHash(hash);
    })
    .then(async (tx) => {
      // setTxnLoading(false);
      // setTxnHash();
      toast(`Approval Successful!`, {
        className: `Toastify__toast-container  Toastify__toast--success`,
        hideProgressBar: true,
      });
      return true;
    })
    .catch((error) => {
      console.log("error");
      console.log(error);
      if (error.code == 4001) {
        toast(`You cancelled the transaction.`, {
          className: `Toastify__toast-container  Toastify__toast--warning`,
          hideProgressBar: true,
        });
      } else {
        toast(`Approval not complete.`, {
          className: `Toastify__toast-container  Toastify__toast--warning`,
          hideProgressBar: true,
        });
      }
      return false;
    });
  return result;
};

export const disableRouter = async (pairAddress) => {
  const provider = new Web3.providers.HttpProvider(c.INFURA_URL);
  const web3 = new Web3(provider);

  const accounts = await window.ethereum.request({
    method: "eth_requestAccounts",
  });

  const bytesData = `0xa22cb465000000000000000000000000${c.CONTRACT.router.substring(
    2,
    500
  )}0000000000000000000000000000000000000000000000000000000000000000`;

  const abi = {
    name: "call",
    type: "function",
    inputs: [
      {
        type: "address",
        name: "target",
      },
      {
        type: "bytes",
        name: "data",
      },
    ],
  };

  const types = ["address", "bytes"];

  const params = [c.CONTRACT.ecliptic, bytesData];

  const callData =
    web3.eth.abi.encodeFunctionSignature(abi) +
    web3.eth.abi.encodeParameters(types, params).substring(2, 5000);

  await window.ethereum.enable();

  const web3js = new Web3(window.ethereum);

  const finalParams = {
    from: accounts[0],
    to: pairAddress,
    value: 0,
    data: callData,
    // gasLimit: 100000,
  };

  const result = await web3js.eth
    .sendTransaction(finalParams)
    .on("transactionHash", (hash) => {
      // setTxnHash(hash);
    })
    .then(async (tx) => {
      // setTxnLoading(false);
      // setTxnHash();
      toast(`Router disabled`, {
        className: `Toastify__toast-container  Toastify__toast--success`,
        hideProgressBar: true,
      });
      return true;
    })
    .catch((error) => {
      console.log("error");
      console.log(error);
      if (error.code == 4001) {
        toast(`You cancelled the transaction.`, {
          className: `Toastify__toast-container  Toastify__toast--warning`,
          hideProgressBar: true,
        });
      } else {
        toast(`Approval not complete.`, {
          className: `Toastify__toast-container  Toastify__toast--warning`,
          hideProgressBar: true,
        });
      }
      return false;
    });
  return result;
};

// [["0xdb20F602975b4F5dB9f2f25A67513f45B81b8C7F", ["2561"]]], 0,0xaF2B0c7c987d163FBD668015aDDF28Cb857CBFD9,"1967279590"

// [[["0x21Ae63080Bbc948d66f65841977B853e74de006e", ["53761"]]],[["0x21Ae63080Bbc948d66f65841977B853e74de006e", ["36353"]]]],0,0xaF2B0c7c987d163FBD668015aDDF28Cb857CBFD9,0xaF2B0c7c987d163FBD668015aDDF28Cb857CBFD9,"1967279590"

// [32001],0,0xaF2B0c7c987d163FBD668015aDDF28Cb857CBFD9,false,0x0000000000000000000000000000000000000000
