import React, { useEffect, useState } from "react";
import metamask from "../assets/img/metamask-fox.svg";
import Modal from "react-modal";
import Sigil from "../components/Sigil";
import * as utils from "../utils";
import { Spinner } from "react-bootstrap";
import Card from "./Card";
import * as c from "../constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle, faGasPump } from "@fortawesome/free-solid-svg-icons";

const ChainModal = (props) => {
  const { message, message2 } = props;
  return (
    <Modal
      ariaHideApp={false}
      isOpen={props.modalState}
      shouldCloseOnOverlayClick={true}
      onAfterOpen={() => {}}
      onRequestClose={props.toggleModal}
      style={styles.customStyles}
      contentLabel="Incorrect Ethereum chain"
    >
      <div
        style={{
          zIndex: 99,
          padding: 20,
          backgroundColor: "white",
        }}
      >
        <div
          style={{
            fontFamily: "Inter",
            backgroundColor: "white",
            fontSize: 18,
            fontWeight: 800,
            marginTop: 20,
            display: "flex",
          }}
        >
          <FontAwesomeIcon
            icon={faInfoCircle}
            style={{ fontSize: 24, marginRight: 20 }}
          />

          <br />
          <div>{message}</div>
        </div>

        <div
          style={{
            fontFamily: "Inter",
            fontSize: 18,
            fontWeight: 600,
            color: "gray",
            paddingTop: 10,
            backgroundColor: "white",
            // paddingBottom: 20,
          }}
        ></div>
        <div style={{ display: "flex", marginBottom: 0, alignItems: "center" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <div
              style={{ fontWeight: 600, fontSize: 20, textAlign: "center" }}
            ></div>
          </div>

          <div
            style={{
              marginLeft: 40,
              alignItems: "center",
              justifyContent: "center",
              fontFamily: "Inter",
              fontWeight: 600,
              fontSize: 18,
            }}
          ></div>
        </div>
      </div>
      <div
        style={{ paddingTop: 20, display: "flex", justifyContent: "center" }}
      >
        <button
          className="btn btn-outline-dark btn-lg"
          style={{
            height: 50,
            borderRadius: 12,
            border: "solid",
            borderWidth: 0.1,
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            borderColor: "rgb(0,30,100,0.3)",
            // paddingLeft: 30,
            fontFamily: "Inter",
            fontWeight: 600,
            fontSize: 18,
            boxShadow: "1px 1px 10px 0px rgba(0,0,0,0.1)",
            width: 150,
            marginBottom: 20,
            // marginLeft: 280,
          }}
          onClick={() => props.toggleChainModal()}
        >
          OK
        </button>
      </div>
    </Modal>
  );
};

const styles = {
  customStyles: {
    backgroundColor: "white",
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      borderRadius: 15,
      boxShadow: "1px 1px 10px 1px rgba(0,0,0,0.1)",
      //   height: 670,
      // width: 700,
      padding: 0,
      maxWidth: 350,
      // margin: 1,
      zIndex: 99,
      backgroundColor: "white",
      alignItems: "center",
      display: "flex",
      flexDirection: "column",
    },
  },
};

export default ChainModal;
