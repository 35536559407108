import * as c from "../constants";
import { azimuth, initContractsPartial } from "azimuth-js";
import ob from "urbit-ob";
import Web3 from "web3";
import * as clipboard from "clipboard-polyfill/text";
import { shapeCode } from "./sigilShapes";
import eth from "../assets/img/eth.svg";
import usdc from "../assets/img/usdc-logo.png";
import wstr from "../assets/img/wstr-logo-192.png";
import wbtc from "../assets/img/wbtc-logo.png";
import usdt from "../assets/img/tether-logo.png";
import weth from "../assets/img/weth-logo.png";
import { toast } from "react-toastify";
// import * as abi from "../abi";

export const isMainChain = async () => {
  if (c.testing) {
    return true;
  }
  const chainId = await window.ethereum.request({ method: "eth_chainId" });

  const result = chainId == 0x1;

  return result;
};

export const getNamesFromPoints = (points) => {
  const ships = points.map((p) => {
    return {
      ...p,
      patp: ob.patp(p.point),
    };
  });
  return ships;
};

export const getGalaxiesFromStars = (points) => {
  const stars = points.map((p) => {
    const starname = ob.patp(p);
    return {
      point: p,
      patp: starname,
      galaxy: `~${starname.substring(4)}`,
    };
  });
  return stars;
};

export const getNamesFromPointsAzimuth = (points) => {
  const ships = points.map((p) => {
    return {
      point: p,
      patp: ob.patp(p),
    };
  });
  return ships;
};

export const checkUrbitLayer = async (ship) => {
  const response = await fetch("https://urbitx.com:443/v1/roller", {
    method: "POST",
    headers: {
      Accept: "*/*",
      "Content-Type": "text/plain",
    },
    body: JSON.stringify({
      jsonrpc: "2.0",
      id: "1337",
      method: "getPoint",
      params: { ship: ship.patp },
    }),
  });

  const jsonRes = response && (await response.json());

  const dominion = jsonRes?.result?.dominion || "";

  let layer = "";
  if (dominion == "l2") {
    layer = "2";
  } else if (dominion == "l1" || dominion == "spawn") {
    layer = "1";
  }

  return layer;
};

export const constructShipObj = async (contracts, ship, layer) => {
  let resultObj;

  if (layer == 2) {
    resultObj = await constructShipObject_Layer2(ship);
  } else {
    resultObj = await constructShipObjectFromAzimuth(contracts, ship);
  }

  return resultObj;
};

export const getPoints_L2 = async (addr, pointsL1) => {
  const rawResponse = await fetch("https://urbitx.com:443/v1/roller", {
    method: "POST",
    headers: {
      Accept: "*/*",
      "Content-Type": "text/plain",
    },
    body: JSON.stringify({
      jsonrpc: "2.0",
      id: "1337", // what should i be using here?
      method: "getOwnedPoints",
      params: { address: addr },
    }),
  });

  const res = await rawResponse.json();
  const allPoints = res.result;

  const L2_or_pending = allPoints.filter(
    (p) => !pointsL1.includes(p.toString())
  );

  // check if these are L2 or pending

  const results = await Promise.all(
    L2_or_pending.map(async (p) => {
      const patp = ob.patp(p);

      const rawResponse2 = await fetch("https://urbitx.com:443/v1/roller", {
        method: "POST",
        headers: {
          Accept: "*/*",
          "Content-Type": "text/plain",
        },
        body: JSON.stringify({
          jsonrpc: "2.0",
          id: "1337",
          method: "getPoint",
          params: { ship: patp },
        }),
      });

      const res2 = await rawResponse2.json();

      const L2_point = res2.result;

      if (L2_point?.dominion == "l2") {
        return p;
      }
    })
  );

  return results;
};

export const getSpawned = async (patp) => {
  const res = await fetch("https://urbitx.com:443/v1/roller", {
    method: "POST",
    headers: {
      Accept: "*/*",
      "Content-Type": "text/plain",
    },
    body: JSON.stringify({
      jsonrpc: "2.0",
      id: "1337",
      method: "getSpawned",
      params: { ship: patp },
    }),
  });

  const child_pts = await res.json();

  return child_pts.result;

  // ***** FOR TESTING *****

  // return [];

  // const provider = new Web3.providers.HttpProvider(c.INFURA_URL);
  // const web3 = new Web3(provider);
  // const contracts = await initContractsPartial(web3, c.CONTRACT.azimuth);

  // const res = await azimuth.getSpawnCount(contracts, ob.patp2dec(patp));

  // const child_pts = res == 0 ? 0 : [21123];
  // return child_pts;
};

export const constructShipObject_Layer2 = async (ship) => {
  const rawResponse = await fetch("https://urbitx.com:443/v1/roller", {
    method: "POST",
    headers: {
      Accept: "*/*",
      // "Content-Type": "text/plain",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      jsonrpc: "2.0",
      id: "1337",
      method: "getPoint",
      params: { ship: ship.patp },
    }),
  });

  const pt = await rawResponse.json();

  const child_pts = await getSpawned(ship.patp);

  const shipObj = pt &&
    child_pts && {
      ...ship,
      address: pt.result.ownership.owner.address,
      type: capitalizeFirstLetter(ob.clan(ship.patp)),
      parent: ob.sein(ship.patp),
      keyRevision: pt.result.network.keys.life,
      spawned: !isZeroAddress(pt.result.ownership.owner.address),
      child_points: child_pts.length,
      unbooted: pt.result.network.keys.life == 0,
      dominion: pt.result.dominion,
    };

  return shipObj;
};

export const constructShipObjectFromAzimuth = async (contracts, ship) => {
  const pt = await azimuth.getPoint(contracts, ship.point);
  const childCount = await getSpawned(ship.patp);

  const { patp } = ship;

  const shipObj = {
    ...ship,
    address: pt.owner,
    type: capitalizeFirstLetter(ob.clan(patp)),
    parent: ob.sein(patp),
    keyRevision: pt.keyRevisionNumber,
    spawned: !isZeroAddress(pt.owner),
    child_points: childCount.length,
    unbooted: pt.keyRevisionNumber == 0,
  };

  return shipObj;
};

export const getPriceDataEth = async () => {
  const apiUrl = `${c.API_URL}/get_price_data`;
  const response = await fetch(apiUrl, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });

  const res = await response.json();

  const resultsInGwei = {
    star: {
      avg: gweiToEth(res.star_avg),
      avg_delta: res.star_delta,
      floor: gweiToEth(res.star_floor.toString()),
    },
    planet: {
      avg: gweiToEth(res.planet_avg),
      avg_delta: res.planet_delta,
    },
  };

  return resultsInGwei;
};

export const getEthPrice = async () => {
  const getEthUrl =
    "https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=USD";
  const result = await fetch(getEthUrl, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });

  const resp = await result.json();

  const ethPrice = resp.USD;

  return ethPrice;
};

export const getPriceData = async () => {
  const getBTCUrl =
    "https://min-api.cryptocompare.com/data/price?fsym=BTC&tsyms=USD";
  const result = await fetch(getBTCUrl, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });

  const resp = await result.json();

  const ethPrice = await getEthPrice();
  const btcPrice = resp.USD;

  const apiUrl = `${c.API_URL}/get_price_data`;

  const response = await fetch(apiUrl, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });

  const res = await response.json();

  const resultsInGwei = {
    star: {
      avg: ethPrice * gweiToEth(res.star_avg.toString()),
      // diff: ethPrice * gweiToEth(res.star_diff.toString()),
      last: ethPrice * gweiToEth(res.star_last.toString()),
      last_eth: gweiToEth(res.star_last.toString()),
      avg_delta: res.star_delta,
      last_delta: res.star_last_delta,
      floor: gweiToEth(res.star_floor.toString()),
      floor_usd: gweiToEth(res.star_floor.toString()) * ethPrice,
    },
    planet: {
      avg: ethPrice * gweiToEth(res.planet_avg.toString()),
      // diff: ethPrice * gweiToEth(res.planet_diff.toString()),
      last: ethPrice * gweiToEth(res.planet_last.toString()),
      last_eth: gweiToEth(res.planet_last.toString()),
      avg_delta: res.planet_delta,
      last_delta: res.planet_last_delta,
      floor: gweiToEth(res.planet_floor.toString()),
      floor_usd: gweiToEth(res.planet_floor.toString()) * ethPrice,
    },
    // volume_eth: gweiToEth(res.vol.toString()),

    // volume_eth: gweiToEth(res.vol_eth.toString()),
    // volume_usdc: gweiToEth(res.vol_usdc.toString()),
    // volume_wbtc: gweiToEth(res.vol_wbtc.toString()),

    volume:
      Number(ethPrice * gweiToEth(res.vol_eth.toString())) +
      Number(gweiToEth(res.vol_usdc.toString())) +
      Number(gweiToEth(res.vol_wbtc.toString()) * btcPrice),

    volume_eth:
      (Number(ethPrice * gweiToEth(res.vol_eth.toString())) +
        Number(gweiToEth(res.vol_usdc.toString())) +
        Number(gweiToEth(res.vol_wbtc.toString()) * btcPrice)) /
      ethPrice,
    vol_delta: res.vol_delta,
    ethPrice: ethPrice,
  };

  return resultsInGwei;
};

export const constructShipObjectsArray = async (azPoints) => {
  const provider = new Web3.providers.HttpProvider(c.INFURA_URL);
  const web3 = new Web3(provider);
  const contracts = await initContractsPartial(web3, c.CONTRACT.azimuth);

  const results = await Promise.all(
    azPoints.map(async (p) => {
      const patp = ob.patp(p);
      const keyRev = await azimuth.getPoint(contracts, p, "state");
      // const childCount = await azimuth.getSpawnCount(contracts, p);
      const childCount = await getSpawned(patp);

      return {
        point: p,
        unbooted: keyRev.keyRevisionNumber == 0,
        patp: patp,
        type: ob.clan(patp),
        child_points: childCount.length,
      };
    })
  );

  const apiUrl = `${c.API_URL}/get_points_data`;
  const response = await fetch(apiUrl, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(results),
  });

  const ships = await response.json();

  const shipObjs = ships.map((s) => {
    const patp = ob.patp(s.point);
    return {
      ...s,
      parent: ob.sein(patp),
      type: s.ship_type,
      // spawned: !isZeroAddress(s.address),
    };
  });

  return shipObjs;
};

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const abbreviateAddress = (address) => {
  if (!address) return;
  if (isZeroAddress(address)) return "0x0";
  return `${address.slice(0, 6)}…${address.slice(-4)}`;
};

export const isZeroAddress = (a) =>
  a === c.ETH_ZERO_ADDR || a === c.ETH_ZERO_ADDR_SHORT;

export const ethEnabled = async () => {
  // this is no longer used.

  const Web3 = require("web3");

  if (window.ethereum) {
    // await window.ethereum.send("eth_requestAccounts");

    window.ethereum
      .request({
        method: "eth_requestAccounts",
      })
      .then((tx) => {
        window.web3 = new Web3(window.ethereum);
        return true;
      })
      .catch((error) => {
        if (error.code == -32002) {
        }
      });
  }
  return false;
};

export const getMetamaskAddress = async () => {
  const Web3 = require("web3");
  let _web3;
  let userAccount;
  const ethereum = window.ethereum;
  if (ethereum) {
    if (!ethereum.selectedAddress) {
      await ethereum.enable(); // <<< ask for permission
    }
    userAccount = ethereum.selectedAddress;
    _web3 = new Web3(ethereum);
  }

  return userAccount;
};

export const convertToUsd = async (eth) => {
  const getEthUrl =
    "https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=USD";
  const resp = await fetch(getEthUrl, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });

  const ethPrice = await resp.json();

  const result = ethPrice && ethPrice.USD * eth;

  if (result > 0) {
    return result;
  } else {
    return 0;
  }
};

// export const getENS = async (address) => {
//   const ethers = require("ethers");
//   const provider = ethers.getDefaultProvider();
//   const name = await provider.lookupAddress(address);

//   return name;
// };

export const convertAmount = (val, dec) => {
  const ethers = require("ethers");
  const gwei = ethers.utils.parseUnits(Number(val).toFixed(12), dec);
  return gwei;
};

export const ethToGwei = (val) => {
  const ethers = require("ethers");
  const gwei = ethers.utils.parseUnits(val, "gwei").toNumber();
  // const gwei = Number(ethers.utils.parseUnits(val, "gwei"));
  return gwei;
};

export const weiToGwei = (val) => {
  const ethers = require("ethers");
  if (!val) return 0;
  const etherString = ethers.utils.formatUnits(val, "gwei");
  return etherString;
};

export const gweiToEth = (val) => {
  const ethers = require("ethers");
  if (!val) return 0;
  const etherString = ethers.utils.formatUnits(val, 9);
  return etherString;
};

export const ethToWei = (val) => {
  const ethers = require("ethers");
  const wei = ethers.utils.parseEther(Number(val).toFixed(12));

  return wei.toString();
};

export const convertProperCurrency = (val, curr) => {
  let res;
  if (curr == c.USDC.name) {
    res = (val * 1000000).toString();
  } else if (curr == c.WSTR.name) {
    res = (val * 1000000).toString();
  } else if (curr == c.WBTC.name) {
    res = (val * 100000000).toString();
  } else {
    res = ethToWei(val);
  }
  return res.toString();
};

export const displayProperCurrency = (val, curr) => {
  let res;
  if (curr == c.USDC.name) {
    res = (val / 1000000000).toString();
  } else if (curr == c.WSTR.name) {
    res = (val / 1000000).toString();
  } else if (curr == c.WBTC.name) {
    res = (val / 1000000).toString();
  } else {
    res = gweiToEth(val);
  }
  return res.toString();
};

export const getMaxApproval = (curr) => {
  let res;
  if (curr == c.USDC.name) {
    res = 2000000000000;
  } else if (curr == c.WBTC.name) {
    res = 900000000;
  }
  return res && res.toString();
};

export const convertFromProperCurrency = (val, curr) => {
  if (!val) return;
  let res;
  if (curr == c.USDC.name) {
    res = (val / 1000).toString();
  } else if (curr == c.WSTR.name) {
    res = (val / 1000).toString();
  } else if (curr == c.WBTC.name) {
    res = (val / 10).toString();
  } else {
    res = Web3.utils.toWei(val.toString(), "gwei");
  }
  return res && res.toString();
};

export const gweiToWei = (val) => {
  // const wei = val * 1000000000;
  const wei = Web3.utils.toWei(val.toString(), "gwei");
  return wei;
};

export const weiToEth = (val) => {
  const ethers = require("ethers");
  if (!val) return 0;
  const etherString = ethers.utils.formatEther(val);
  return etherString;
};

// myContract.events.MyEvent({// Using an array means OR: e.g. 20 or 23
//   fromBlock: 13500187
// }, function(error, event){ console.log(event); })
// .on('data', function(event){
//   console.log(event); // same results as the optional callback above
// })
// .on('changed', function(event){
//   // remove event from local database
// })
// .on('error', console.error);

export const getPlanetsTest = async () => {
  const provider = new Web3.providers.HttpProvider(c.INFURA_URL);
  const web3 = new Web3(provider);
  const contracts = await initContractsPartial(web3, c.CONTRACT.azimuth);

  const points = await azimuth.getUnspawnedChildren(contracts, parseInt(3454));
  return points;
};

export const windowLoadTest = async (data, start, stop) => {
  const limitPoints = data.slice(start, stop);
  const result = limitPoints && getNamesFromPoints(limitPoints);

  return result;
};

export const isNumeric = (value) => {
  return /^-?\d+$/.test(value);
};

export const isFloat = (value) => {
  return /^\-?[0-9]+(e[0-9]+)?(\.[0-9]+)?$/.test(value);
};

export const findSpecialPatps = (patp) => {
  const ps = [
    "balled",
    "baller",
    "banned",
    "banter",
    "barfed",
    "barfer",
    "batted",
    "battel",
    "batter",
    "bolder",
    "boldyr",
    "biches",
    "bitdev",
    "dapper",
    "darrel",
    "darren",
    "darryl",
    "docter",
    "doctyr",
    "doller",
    "dotnet",
    "fabled",
    "faster",
    "fastyr",
    "forbes",
    "happen",
    "harden",
    "harder",
    "harper",
    "hasten",
    "hatred",
    "hidden",
    "hodler",
    "holder",
    "holdyr",
    "hodlyr",
    "hopper",
    "larper",
    "lasted",
    "laster",
    "littel",
    "littul",
    "magnet",
    "magnum",
    "magnus",
    "mapped",
    "martyn",
    "martyr",
    "master",
    "mastyr",
    "midnyt",
    "misder",
    "mister",
    "missed",
    "misled",
    "missel",
    "missyl",
    "mistyr",
    "molten",
    "parden",
    "pilled",
    "piller",
    "possum",
    "ranted",
    "ranter",
    "rapper",
    "rapped",
    "rapter",
    "riches",
    "ridden",
    "riddel",
    "ripped",
    "ripper",
    "rollex",
    "salted",
    "salter",
    "sampel",
    "signed",
    "signer",
    "sonnet",
    "sovryn",
    "tapper",
    "tapped",
    "tasted",
    "taster",
    "tinder",
    "walnut",
    "walrus",
    "wanted",
    "wantyd",
    "wanter",
    "wander",
    "wandyr",
    "watsup",
    "winner",
    "winnyr",
    "winter",
    "wisdem",
    "wisdym",
    "wishes",
    "witnes",
    "worsen",
  ];

  return ps.reduce((acc, x) => acc || patp.includes(x), false);
};

export const findAlmostDoubles = (patp) => {
  if (
    patp.substring(1, 5) == patp.substring(8, 12) &&
    patp.substring(1, 7) != patp.substring(8, 14)
  )
    return true;
};

export const findDoubles = (patp) => {
  if (patp.substring(1, 7) == patp.substring(8, 14)) return true;
};

export const findSameEndings = (patp) => {
  if (patp.substring(3, 7) == patp.substring(10, 14)) return true;
};

export const getWstrBalance = async (address) => {
  const provider = new Web3.providers.HttpProvider(
    `https://mainnet.infura.io/v3/${c.INFURA_ID}`
  );
  const web3 = new Web3(provider);

  // const accounts = await window.ethereum.request({
  //   method: "eth_requestAccounts",
  // });

  const tokenAddress = c.CONTRACT_ADDRESS.MAINNET.wrappedStar;
  const walletAddress = address;

  const contract = new web3.eth.Contract(c.CONTRACT_WSTR_ABI, tokenAddress);

  const result = await contract.methods.balanceOf(walletAddress).call();

  const format = web3.utils.fromWei(result);

  return format;
};

export const isLockedContract = (address) => {
  if (
    address == c.CONTRACT.linearStarRelease ||
    address == c.CONTRACT.conditionalStarRelease
  ) {
    return true;
  } else {
    return false;
  }
};

export const getLockedBalance = async (address) => {
  if (
    address == c.CONTRACT.linearStarRelease ||
    address == c.CONTRACT.conditionalStarRelease
    // ||
    // c.testing
  ) {
    return {
      lsr: 0,
      csr: 0,
      total: 0,
    };
  }

  const provider = new Web3.providers.HttpProvider(c.INFURA_URL);
  const web3 = new Web3(provider);

  const contractAbi_LSR = new web3.eth.Contract(
    c.CONTRACT_LSR_ABI,
    c.CONTRACT.linearStarRelease
  );

  const contractAbi_CSR = c.testing
    ? null
    : new web3.eth.Contract(
        c.CONTRACT_CSR_ABI,
        c.CONTRACT.conditionalStarRelease
      );

  const batchLSR = await contractAbi_LSR.methods
    .getRemainingStars(address)
    .call();
  const batchCSR = c.testing
    ? []
    : await contractAbi_CSR.methods.getRemainingStars(address).call();

  const batchLSR_int = batchLSR.map((s) => {
    return { point: Number(s), patp: ob.patp(Number(s)) };
  });
  const batchCSR_int = batchCSR.map((s) => {
    return { point: Number(s), patp: ob.patp(Number(s)) };
  });

  const res = {
    lsr: batchLSR_int,
    csr: batchCSR_int,
    total: Number(batchLSR_int.length) + Number(batchCSR_int.length),
  };

  return res;
};

export const getWalletBalanceEth = async () => {
  const provider = new Web3.providers.HttpProvider(c.INFURA_URL);
  const web3 = new Web3(provider);

  const accounts = await window.ethereum.request({
    method: "eth_requestAccounts",
  });

  const balance = await web3.eth.getBalance(accounts[0]);

  return balance;
};

export const getAddressContents = async (address) => {
  const provider = new Web3.providers.HttpProvider(c.INFURA_URL);
  const web3 = new Web3(provider);

  const balance = await web3.eth.getBalance(address);
  return balance;
};

// export const testUploadChildShips = async (azPoints) => {
//   const pointThing = await azPoints.map((p) => parseInt(p));

//   const apiUrl = `${c.API_URL}/get_points_data`;
//   const response = await fetch(apiUrl, {
//     method: "PUT",
//     headers: {
//       Accept: "application/json",
//       "Content-Type": "application/json",
//     },
//     body: JSON.stringify({ point: pointThing }),
//   });
// };

export const getChildrenCalc = (_pnt) => {
  const pnt = parseInt(_pnt);
  let shipArr = [];
  if (pnt >= 0 && pnt <= 255) {
    // galaxy
    for (let i = 1; i < 256; i++) {
      shipArr.push({ point: pnt + i * 256, patp: ob.patp(pnt + i * 256) });
    }
  } else if (pnt >= 256 && pnt <= 65535) {
    // star
    for (let i = 1; i < 65535; i++) {
      const pp = ob.patp(pnt + 65536 * i);
      shipArr.push({
        point: pnt + 65536 * i,
        patp: pp,
        rareness: shapeCode(pp),
      });
    }
  } else if (pnt > 65536) {
    // planet
    return;
  }

  return shipArr;
};

export const getStarsAtAddress = async (gxy_pnt) => {
  const pnt = parseInt(gxy_pnt);
  if (pnt >= 256) return;

  const provider = new Web3.providers.HttpProvider(c.INFURA_URL);
  const web3 = new Web3(provider);
  const contracts = await initContractsPartial(web3, c.CONTRACT.azimuth);

  const gxy_add = await azimuth.getOwner(contracts, gxy_pnt);

  const points =
    gxy_add && contracts && (await azimuth.getOwnedPoints(contracts, gxy_add));

  let starList = [];

  for (let i = 1; i < 256; i++) {
    const p = gxy_pnt + i * 256;
    if (points.includes(p.toString())) {
      starList.push(Number(p));
    }
  }

  return starList;
};

export const getStarsFromGalaxy = async (gxy_pnt) => {
  const pnt = parseInt(gxy_pnt);
  if (pnt >= 256) return;

  const provider = new Web3.providers.HttpProvider(c.INFURA_URL);
  const web3 = new Web3(provider);
  const contracts = await initContractsPartial(web3, c.CONTRACT.azimuth);

  const gxy_add = await azimuth.getOwner(contracts, pnt);

  // const spawned = await azimuth.azimuth.getSpawnCount(contracts, pnt);
  // const unspawned = 255 - spawned;

  let starList = [];

  for (let i = 1; i < 256; i++) {
    const add = await azimuth.getOwner(contracts, pnt + i * 256);

    starList.push({
      point: pnt + i * 256,
      patp: ob.patp(pnt + i * 256),
      unspawned: isZeroAddress(add),
      address: add,
      lsr: add.toLowerCase() == c.CONTRACT.linearStarRelease.toLowerCase(),
      csr: add.toLowerCase() == c.CONTRACT.conditionalStarRelease.toLowerCase(),
      unlocked: gxy_add.toLowerCase() == add.toLowerCase(),
      foreignOwned:
        add.toLowerCase() != gxy_add.toLowerCase() &&
        add.toLowerCase() != c.CONTRACT.linearStarRelease.toLowerCase() &&
        add.toLowerCase() != c.CONTRACT.conditionalStarRelease.toLowerCase() &&
        !isZeroAddress(add.toLowerCase()),
    });
  }

  return starList;
};

export const getColorSkin = (patp) => {
  const hex = ob.patq2hex(patp);
  const point = ob.patp2dec(patp);

  const nextPlanet = ob.patp(Math.round(point / 2));
  const nextPlanetHex = ob.patq2hex(nextPlanet);

  const nextPlanet2 = ob.patp(Math.round(point / 2 + 104));
  const nextPlanetHex2 = ob.patq2hex(nextPlanet2);

  const h1 = parseInt(hex.substring(0, 2), 16);
  const h2 = parseInt(nextPlanetHex2.substring(0, 2), 16);
  const h3 = parseInt(hex.substring(4, 6), 16);

  const h4 = parseInt(hex.substring(6, 8), 16);
  const h5 = parseInt(nextPlanetHex.substring(0, 2), 16);
  const h6 = parseInt(nextPlanetHex.substring(2, 4), 16);

  const rgb1 = `rgb(${h1},${h2},${h3},1)`;
  const rgb2 = `rgb(${h4},${h5},${h6},1)`;

  const colors = [rgb1, rgb2];

  return colors;
};

export const getRandomColor = (point) => {
  const min = 0;
  const max = 255;

  const r = Math.floor(Math.random() * (max - min + 1) + min);
  const b = Math.floor(Math.random() * (max - min + 1) + min);
  const g = Math.floor(Math.random() * (max - min + 1) + min);

  const rgb = `rgb(${r},${g},${b},1)`;

  return rgb;
};

export const getRandomPoint = () => {
  const min = 0;
  const max = 4294901760;

  return Math.floor(Math.random() * (max - min + 1) + min);
};

export const getAllGalaxies = () => {
  var points = [];
  for (var i = 0; i < 256; i++) {
    points.push(i);
  }

  const result = getNamesFromPointsAzimuth(points);
  return result;
};

export const getWstrTrend = async () => {
  const apiUrl = `${c.API_URL}/get_wstr_trend`;

  const response = await fetch(apiUrl, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });

  const res = await response.json();

  return res;
};

// const getDates = (startDate, stopDate) => {
//   var dateArray = [];
//   var currentDate = moment(startDate);
//   var stopDate = moment(stopDate);
//   while (currentDate <= stopDate) {
//     dateArray.push(moment(currentDate).format("YYYY-MM-DD"));
//     currentDate = moment(currentDate).add(1, "days");
//   }
//   return dateArray;
// };

// export const getStarTrend = async () => {
//   const apiUrl = `${c.API_URL}/get_star_trend`;

//   const response = await fetch(apiUrl, {
//     method: "GET",
//     headers: {
//       Accept: "application/json",
//       "Content-Type": "application/json",
//     },
//   });

//   let res = await response.json();

//   let popped = res.pop();

//   const dates = getDates("2022-01-23", "2022-04-30");

//   let lastPrice = 0;
//   const resDates = dates.map((d) => {
//     const price = res.find((item) => item[0] == d);

//     if (price) {
//       lastPrice = price[1];
//     }
//     return { date: d, price: lastPrice };
//   });

//   // console.log(resDates[1]);

//   return resDates;
// };

export const getGalaxiesFromDb = async () => {
  const apiUrl = `${c.API_URL}/get_galaxies`;

  const response = await fetch(apiUrl, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });

  const res = await response.json();

  return res;
};

export const copyHandler = async (val) => {
  await clipboard.writeText(val);
};

export const isValidEthAddress = (rawInput) => {
  try {
    const address = Web3.utils.isAddress(rawInput);
    if (address) return true;
  } catch (e) {
    // console.log(e);
    return false;
  }
};

export const getGasPrice = async () => {
  const provider = new Web3.providers.HttpProvider(c.INFURA_URL);
  const web3 = new Web3(provider);

  const result = await web3.eth.getGasPrice();
  const resultGwei = result && weiToGwei(result.toString());

  return resultGwei;
};

export const checkValidListing = async (listing) => {
  // if (c.testing) return;
  const provider = new Web3.providers.HttpProvider(c.INFURA_URL);
  const web3 = new Web3(provider);
  const contracts = await initContractsPartial(web3, c.CONTRACT.azimuth);

  const contract_v1 = new web3.eth.Contract(
    c.ABI_URBITEX_EXCHANGE,
    c.CONTRACT.exchange_v1
  );

  const contract_v2 = new web3.eth.Contract(
    c.ABI_URBITEX_EXCHANGE_V2,
    c.CONTRACT.exchange_v2
  );

  const owner = await azimuth.getOwner(contracts, listing);
  const asset_v1 = await contract_v1.methods.getAssetInfo(listing).call();
  const asset_v2 = await contract_v2.methods.getAssetInfo(listing).call();

  // console.log(asset_v1);
  // console.log(asset_v2);
  // console.log(owner);

  // overkill on the checks because i want to make sure any errors from
  // these checking methods don't cause this to somehow be 'true', and mistakenly
  // remove somebody's listing

  if (
    asset_v1[0] != owner &&
    asset_v2[0] != owner &&
    owner != c.ETH_ZERO_ADDR &&
    owner != null &&
    owner != undefined &&
    asset_v1[0] != null &&
    asset_v1[0] != undefined &&
    asset_v2[0] != null &&
    asset_v2[0] != undefined

    // isValidEthAddress(asset[0]) &&
    // isValidEthAddress(owner)
  ) {
    toast(`Sorry, this asset is no longer available for sale.`, {
      className: `Toastify__toast-container  Toastify__toast--notice`,
      hideProgressBar: true,
    });

    const apiUrl = `${c.API_URL}/remove_stale_listing`;
    const response = await fetch(apiUrl, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ point: listing }),
    });

    return true;
  } else {
    return false;
  }
};

export const checkIfOwner = async () => {
  // Check if browser is running Metamask
  let web3;
  if (window.ethereum) {
    web3 = new Web3(window.ethereum);
  } else if (window.web3) {
    web3 = new Web3(window.web3.currentProvider);
  }

  // Check if User is already connected by retrieving the accounts
  if (!web3) {
    return;
  } else {
    const result = await web3.eth.getAccounts();
    return result;
  }
};

// export const estimateGas = async (points) => {
//   const ethers = require("ethers");

//   const provider = ethers.getDefaultProvider();
//   const contract = new ethers.Contract(
//     "0x1b4e2aCc130F53dd9508e106D1Ad79b581C6e264",
//     c.BATCH_SALE_ABI,
//     provider
//   );

//   console.log(points);

//   const res = await contract.estimateGas.purchase(points);

//   return res;
// };

export const postWstr = async (price_eth) => {
  const getEthUrl =
    "https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=USD";
  const result = await fetch(getEthUrl, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });

  const resp = await result.json();

  const ethPrice = resp.USD;

  const apiUrl = `${c.API_URL}/post_wstr`;
  const response = await fetch(apiUrl, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      price_eth: price_eth,
      price_usd: price_eth * ethPrice,
    }),
  });

  const res = await response.json();

  return res;
};

export const getERCPrice = async (token1, token2) => {
  const getEthUrl = `https://min-api.cryptocompare.com/data/price?fsym=${token1}&tsyms=${token2}`;
  const result = await fetch(getEthUrl, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });

  const resp = await result.json();

  const ercPrice = resp[token2];

  return ercPrice;
};

export const renderDigits = (val) => {
  let formattedValue;
  if (val > 0) {
    formattedValue = Number(val).toFixed(2);
  } else {
    formattedValue = Number(val).toFixed(0);
  }
  return formattedValue;
};

export const checkStaleListings = async (points) => {
  const provider = new Web3.providers.HttpProvider(c.INFURA_URL);
  const web3 = new Web3(provider);
  const contracts = await initContractsPartial(web3, c.CONTRACT.azimuth);

  const contract = new web3.eth.Contract(
    c.ABI_URBITEX_EXCHANGE_V2,
    c.CONTRACT.exchange_v2 // ALSO CHECK OTHER EXCHANGE CONTRACTS
  );

  const results = await Promise.all(
    points.map(async (p) => {
      const patp = ob.patp(p);
      const owner = await azimuth.getOwner(contracts, p);
      const asset = await contract.methods.getAssetInfo(p).call();
      if (asset[0] != owner) {
        return {
          point: p,
          listedOwner: asset[0],
          currentOwner: owner,
          patp: patp,
        };
      }
    })
  );

  const resultsNext = results && results.filter((pt) => pt?.point > 0);

  // console.log(resultsNext);

  return resultsNext;
};

export const getLogo = (currency) => {
  let coinLogo;
  if (currency == c.ETH.name) {
    coinLogo = eth;
  } else if (currency == c.WSTR.name) {
    coinLogo = wstr;
  } else if (currency == c.USDC.name) {
    coinLogo = usdc;
  } else if (currency == c.WBTC.name) {
    coinLogo = wbtc;
  } else if (currency == c.USDT.name) {
    coinLogo = usdt;
  } else if (currency == c.WETH.name) {
    coinLogo = weth;
  } else {
    coinLogo = eth;
  }
  return coinLogo;
};

export const checkAdminWarning = async () => {
  const apiUrl = `${c.API_URL}/check_admin_warning`;

  const response = await fetch(apiUrl, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });

  const res = await response.json();

  return res;
};

export const refreshStaticPrices = async () => {
  // to refresh the prices stored in the database for sorting purposes
  const ethPrice = await getEthPrice();

  const apiUrl = `${c.API_URL}/get_listed_ships`;

  const response = await fetch(apiUrl, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });

  const result = await response.json();
  const ships = result.data;
};

export const getSponsored_L2 = async (ship) => {
  const rawResponse = await fetch("https://urbitx.com:443/v1/roller", {
    method: "POST",
    headers: {
      Accept: "*/*",
      "Content-Type": "text/plain",
    },
    body: JSON.stringify({
      jsonrpc: "2.0",
      id: "1337", // what should i be using here?
      method: "getSponsoredPoints",
      params: { ship },
    }),
  });

  const res = await rawResponse.json();
  const spPoints = res.result;

  return spPoints;
};

export const getActivePlanets = async (star) => {
  const apiUrl = `${c.API_URL}/get_viz_planets/${star}`;

  const response = await fetch(apiUrl, {
    headers: {
      "Content-Type": "application/json",
    },
  });

  const resData = await response.json();

  return resData;
};

export const getGroups = async () => {
  const apiUrl = `${c.API_URL}/get_groups`;

  const response = await fetch(apiUrl, {
    headers: {
      "Content-Type": "application/json",
    },
  });

  const resData = await response.json();

  return resData;
};

export const searchActiveNodes = async (ship) => {
  const apiUrl = `${c.API_URL}/get_viz_search/${ship}`;

  const response = await fetch(apiUrl, {
    headers: {
      "Content-Type": "application/json",
    },
  });

  const resData = await response.json();

  return resData;
};

export const addGroup = async (group) => {
  const apiUrl = `${c.API_URL}/add_group`;
  const response = await fetch(apiUrl, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      name: group.name,
      path: group.path,
      patp: group.patp,
    }),
  });

  const res = await response.json();

  return res;
};

export const setColors = async (colors) => {
  const apiUrl = `${c.API_URL}/set_color`;
  const response = await fetch(apiUrl, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      main_color: colors?.main_color || "#2B384E",
      stroke_color: colors?.stroke_color || "black",
      text_color: colors?.text_color || "white",
      patp: colors.patp,
    }),
  });

  const res = await response.json();

  return res;
};

export const checkIfPoolStar = async (starAddress) => {
  const pairs = await getAllPairs();

  const inPool = pairs.find(
    (s) => s?.pool_address?.toLowerCase() == starAddress?.toLowerCase()
  );

  return inPool?.approved;
};

export const getPoolTransactions = async (poolAddress) => {
  const apiUrl = `${c.API_URL}/get_pool_transactions/${poolAddress}`;

  const response = await fetch(apiUrl, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });

  const res = await response.json();

  return res;
};

export const getAllMyPoolsTransactions = async (userAddress) => {
  const apiUrl = `${c.API_URL}/get_all_my_pools_transactions/${userAddress}`;

  const response = await fetch(apiUrl, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });

  const res = await response.json();

  return res;
};

export const getAllPoolsTransactions = async () => {
  const apiUrl = `${c.API_URL}/get_all_pools_transactions`;

  const response = await fetch(apiUrl, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });

  const res = await response.json();

  return res;
};

export const getUserPairs = async (userAddress) => {
  const apiUrl = `${c.API_URL}/get_pairs/${userAddress.toLowerCase()}`;

  const response = await fetch(apiUrl, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });

  const res = await response.json();

  return res;
};

export const getAllPairs = async () => {
  const apiUrl = `${c.API_URL}/get_all_pairs`;

  const response = await fetch(apiUrl, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });

  const res = await response.json();

  return res;
};

export const toFixedIfNecessary = (value) => {
  if (value == 0 || isNaN(value)) return 0;
  return +parseFloat(value).toFixed(8) || "";
};
