import React, { useState, useEffect } from "react";
import NavBar from "../components/NavBar";
import { useHistory } from "react-router-dom";
import logo from "../assets/img/1200px-Urbit_Logo.svg.png";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationCircle,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";

const NoInternetConnection = (props) => {
  const history = useHistory();
  // state variable holds the state of the internet connection
  const [isOnline, setOnline] = useState(true);

  // On initization set the isOnline state.
  useEffect(() => {
    setOnline(navigator.onLine);
  }, []);

  // event listeners to update the state
  window.addEventListener("online", () => {
    setOnline(true);
  });

  window.addEventListener("offline", () => {
    setOnline(false);
  });

  // if user is online, return the child component else return a custom component
  if (isOnline) {
    return props.children;
  } else {
    return (
      <React.Fragment>
        <NavBar />
        <div style={styles.container}>
          <div
            style={{
              display: "flex",
              fontFamily: "inter",
              alignItems: "center",
              fontWeight: 1000,
              fontSize: 70,
              height: 100,
              justifyContent: "center",
            }}
          >
            {/* No Internet */}
          </div>

          <div style={{ fontSize: 30, fontWeight: 500, marginBottom: 20 }}>
            <FontAwesomeIcon
              icon={faExclamationTriangle}
              style={{
                fontSize: 24,
                color: "#d9534f",
                marginRight: 15,
                marginBottom: 2,
              }}
            />
            No network connection
          </div>
          <Link to="/" className="text-dark nounderline pr-3">
            <div
              className="btn btn-outline-dark btn-lg"
              onClick={() => history.go(0)}
              role="button"
              style={{
                borderRadius: 6,
                // width: 200,
                // height: 65,
                marginTop: 50,
                display: "flex",
                textAlign: "center",
                justifyContent: "center",
                margin: 10,
              }}
            >
              <div
                style={{
                  fontWeight: "bold",
                  padding: 3,
                  fontSize: 16,
                }}
              >
                Try again
              </div>
            </div>
          </Link>
        </div>
      </React.Fragment>
    );
  }
};

const styles = {
  container: {
    // paddingTop: 100,
    height: "100%",
    display: "flex",
    flexDirection: "column",
    // backgroundColor: "rgba(0,0,0,0.1)",
    // paddingBottom: 400,
    marginTop: 100,
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
  },
  contentContainer: {
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
  },
  header: {
    fontSize: 28,
    marginBottom: 30,
  },
};

export default NoInternetConnection;
