import { Motion, spring } from "react-motion";

const Fade = ({ isOn, children }) => (
  <Motion
    defaultStyle={{ opacity: isOn ? 1 : 0 }}
    style={{ opacity: spring(isOn ? 1 : 0) }}
  >
    {(style) => <div style={style}>{children}</div>}
  </Motion>
);

export default Fade;
