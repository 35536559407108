import {
  GET_PLANETS_FOR_SALE,
  GET_CHILDREN,
  CLEAR_CHILDREN,
  SET_PRICE_FILTER,
  SET_CHILD_RANGE_FILTER,
  SET_SHIP_FILTER,
  SET_UNBOOTED_FILTER,
  SET_PROPERTIES_FILTER,
  SET_CHILD_FILTER,
  SET_SPECIAL_FILTER,
  SET_CURRENCY_FILTER,
  GET_LISTED_SHIPS,
  SET_CONTRACTS,
  SET_COLOR,
  SET_SKIN,
  LIST_FOR_SALE,
  GET_ETH_PRICE,
  GET_NEXT_DATA,
  GET_SPONSORING,
  GET_ALL_GALAXIES,
  GET_TRANSACTIONS,
  ADD_TO_BASKET,
  REMOVE_FROM_BASKET,
  CLEAR_BASKET,
  DISMISS_FAQ_MESSAGE,
} from "../actions/planets";

const initialState = {
  basket: [],
  forSale: [],
  listed: [],
  totalChildren: 0,
  children: [],
  sponsoring: [],
  totalSponsoring: [],
  priceFilter: [],
  color: [], //["black", "white"],
  skin: false,
  childRangeFilter: [0, 256],
  shipFilter: { planet: true, star: false, galaxy: false },
  propertiesFilter: { unbooted: false, booted: true },
  unbootedFilter: false,
  childFilter: { spawnable: true, sponsoring: false },
  specialFilter: {
    englishLike: false,
    doubles: false,
    almostDoubles: false,
    sameEndings: false,
    sigilFullCircle: false,
    sigilAllCircles: false,
    sigilEvilFace: false,
    sigilHappyFace: false,
    sigilSadFace: false,
    sigilShield: false,
    sigilFullSquare: false,
    sigilHollowCircle: false,
    sigilAllWings: false,
    sigilHalfMoons: false,
  },
  currencyFilter: {
    eth: false,
    wbtc: false,
    wstr: false,
    usdc: false,
  },
  currentEthPrice: null,
  nextData: [],
  galaxies: [],
  transactions: [],
  contracts: null,
  showFaqMessage: true,
};

export default (state = initialState, action) => {
  const payload = action.payload;
  switch (action.type) {
    case GET_PLANETS_FOR_SALE: {
      return { ...state, forSale: payload.slice, total: payload.total };
    }
    case GET_CHILDREN: {
      return { ...state, children: [] };
    }
    case GET_SPONSORING: {
      return { ...state, sponsoring: payload };
    }
    case CLEAR_CHILDREN: {
      return { ...state, children: [] };
    }
    case SET_PRICE_FILTER: {
      return { ...state, priceFilter: payload };
    }
    case SET_CHILD_RANGE_FILTER: {
      return { ...state, childRangeFilter: payload };
    }
    case SET_SHIP_FILTER: {
      return { ...state, shipFilter: payload };
    }
    case SET_PROPERTIES_FILTER: {
      return { ...state, propertiesFilter: payload };
    }
    case SET_UNBOOTED_FILTER: {
      return { ...state, properties: { unbooted: payload } };
    }
    case SET_CHILD_FILTER: {
      return { ...state, childFilter: payload };
    }
    case GET_LISTED_SHIPS: {
      return {
        ...state,
        listed: payload.slice,
        total: payload.total,
      };
    }
    case GET_ETH_PRICE: {
      return { ...state, currentEthPrice: payload };
    }
    case GET_NEXT_DATA: {
      return { ...state, nextData: payload };
    }
    case GET_ALL_GALAXIES: {
      return { ...state, children: payload };
    }
    case GET_TRANSACTIONS: {
      return { ...state, transactions: payload };
    }
    case SET_SPECIAL_FILTER: {
      return {
        ...state,
        specialFilter: { ...state.specialFilter, ...payload },
      };
    }
    case SET_CURRENCY_FILTER: {
      return {
        ...state,
        currencyFilter: { ...state.currencyFilter, ...payload },
      };
    }
    case SET_CONTRACTS: {
      return { ...state, contracts: payload };
    }

    case SET_COLOR: {
      return { ...state, color: payload };
    }

    case SET_SKIN: {
      return { ...state, skin: payload };
    }

    case ADD_TO_BASKET: {
      if (state.basket.find((p) => p == payload)) {
        return state;
      }

      return { ...state, basket: [...state.basket, payload] };
    }
    case REMOVE_FROM_BASKET: {
      const newBasket = state.basket.filter((p) => p != payload);

      return { ...state, basket: newBasket };
    }

    case CLEAR_BASKET: {
      return { ...state, basket: [] };
    }

    case DISMISS_FAQ_MESSAGE: {
      return { ...state, showFaqMessage: false };
    }

    default: {
      return state;
    }
  }
};
