import React, { useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import ob from "urbit-ob";
import * as utils from "../utils";
import ReactTooltip from "react-tooltip";
import Spinner from "react-bootstrap/Spinner";
import Web3 from "web3";
import * as c from "../constants";

const SearchBar = (props) => {
  const { value, width, height, placeholder } = props;
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm();

  // const [showError, setShowError] = useState(false);
  const [loading, setLoading] = useState();

  useEffect(() => {
    return () => {
      setLoading(false);
      // cleanup on unmount
    };
  }, []);

  // const ref = useRef(null);
  const ref = useRef(null);
  const isValidInput = (val) => {
    if (utils.isNumeric(val)) {
      if (parseInt(val) >= 0 && parseInt(val) < Math.pow(2, 32)) {
        return true;
      }
    } else if (ob.isValidPatq(val)) {
      return true;
    }

    return false;
  };

  const onSubmit = (value) => {
    setLoading(true);

    if (
      value.search.slice(value.search.length - 4, value.search.length) == ".eth"
    ) {
      const provider = new Web3.providers.HttpProvider(c.INFURA_URL);
      const web3 = new Web3(provider);

      web3.eth.ens
        .getAddress(value.search)
        .then(function (address) {
          props.handleSubmit(address);
          setLoading(false);
          reset({
            search: "",
          });
        })
        .catch((error) => {
          handleError();
          setLoading(false);
        });
      return null;
    }

    if (utils.isValidEthAddress(value.search)) {
      props.handleSubmit(value.search);
      setLoading(false);
      reset({
        search: "",
      });

      return null;
    }

    let val;
    if (value.search === "") {
      setLoading(false);
      return null;
    }
    if (value.search.toString().length > 14) return handleError();

    if (utils.isNumeric(value.search)) {
      val = parseInt(value.search);
    } else if (value.search.substring(0, 1) == "~") {
      val = value.search.toLowerCase();
    } else if (value.search.substring(0, 1) != "~")
      val = `~${value.search.toLowerCase()}`;

    if (utils.isNumeric(val)) {
      if (val >= 0 && val <= 4294901760) {
        props.handleSubmit(val);
        reset({
          search: "",
        });
        setTimeout(() => {
          setLoading(false);
        }, 2500);
      } else {
        handleError();
      }
    } else if (ob.isValidPatq(val)) {
      props.handleSubmit(val);
      reset({
        search: "",
      });
      setTimeout(() => {
        setLoading(false);
      }, 2500);
    } else {
      handleError();
    }
  };

  const handleError = () => {
    setLoading(false);
    ReactTooltip.show(ref.current);
    setTimeout(() => {
      ReactTooltip.hide(ref.current);
    }, 1500);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div
          className="form-group"
          style={{
            display: "flex",
            alignItems: "center",
            marginLeft: 14,
          }}
        >
          {/* <label for="exampleInputEmail1">Email address</label> */}

          <FontAwesomeIcon
            icon={faSearch}
            style={{
              fontSize: 18,
              marginRight: -32,
              // color: "black",
              color: "#326da8",
              opacity: 0.6,
              zIndex: 0,
            }}
          />

          {/* <ReactTooltip effect="solid" type="error" event={showError} /> */}
          <ReactTooltip id="sample2" type="error" />
          <div
            ref={ref}
            data-for="sample2"
            data-event="fakeEvent"
            // style={{ fontFamily: "Source Code Pro" }}
            data-tip={"Not a valid Urbit ID or Address"}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <input
                id="search"
                onClick={() => {
                  ReactTooltip.hide(ref.current);
                }}
                // ref={fooRef}
                {...register("search")}
                autoComplete="off"
                autoCorrect="off"
                type="text"
                className="searchBar"
                placeholder={placeholder}
                onFocus={(e) => (e.target.placeholder = "")}
                onBlur={(e) => (e.target.placeholder = placeholder)}
                style={{
                  width,
                  height,
                  paddingLeft: 50,
                  paddingRight: 20,
                  borderRadius: 30,
                  borderWidth: 1,
                  borderColor: "rgba(0,30,100,0.2)",
                }}
              />
              <div style={{ marginLeft: "-10%", fontSize: 14 }}>
                {loading && (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                )}
              </div>
            </div>
          </div>
          {/* <input type="submit" /> */}
        </div>
      </form>

      {/* 
      <form onSubmit={handleSubmit2}>
        <div
          className="form-group"
          style={{
            display: "flex",
            alignItems: "center",
            marginLeft: 14,
          }}
        >
          

          <FontAwesomeIcon
            icon={faSearch}
            style={{
              fontSize: 18,
              marginRight: -32,
              color: "black",
              opacity: 0.7,
              zIndex: 1,
            }}
          />

          <input
            value={value}
            autoComplete="off"
            style={{ width, height, paddingLeft: 50 }}
            onChange={(e) => handleChange(e.target.value)}
            type="text"
            className="form-control"
            id="searchBar"
            placeholder={placeholder}
          />
          <small id="search" className="form-text text-muted"></small>
        </div>
      </form> */}
    </>
  );
};

export default SearchBar;
