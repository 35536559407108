export const embel = (patp) => {
  const isGalaxy = patp.length == 4;

  const pre1 = patp.substring(1, 4);
  const suf1 = patp.substring(4, 7);
  const pre2 = patp.substring(8, 11);
  const suf2 = patp.substring(11, 14);

  const prefix = [
    { noun: "doz", symbol: "⚫", word: "the" },
    { noun: "mar", symbol: "1️⃣", word: "prime" },
    { noun: "bin", symbol: "🗑", word: "discarded" },
    { noun: "wan", symbol: "💕", word: "coveted" },
    { noun: "sam", symbol: "👥", word: "identical" },
    { noun: "lit", symbol: "🔥", word: "impassioned" },
    { noun: "sig", symbol: "🖋", word: "contracted" },
    { noun: "hid", symbol: "😶‍", word: "obscure" },
    { noun: "fid", symbol: "🔺", word: "greater" },
    { noun: "lis", symbol: "🔻", word: "lesser" },
    { noun: "sog", symbol: "💦", word: "drenched" },
    { noun: "dir", symbol: "📁", word: "collector of" },
    { noun: "wac", symbol: "🤪", word: "broken" },
    { noun: "sab", symbol: "✅", word: "honest" },
    { noun: "wis", symbol: "🧙‍", word: "sage of" },
    { noun: "sib", symbol: "🥷", word: "covert" },
    { noun: "rig", symbol: "⛵", word: "hoisted" },
    { noun: "sol", symbol: "☀", word: "shining" },
    { noun: "dop", symbol: "☕", word: "jittery" },
    { noun: "mod", symbol: "💂", word: "guardian of" },
    { noun: "fog", symbol: "🌫", word: "mysterious" },
    { noun: "lid", symbol: "🧢", word: "limited" },
    { noun: "hop", symbol: "🦘", word: "kinetic" },
    { noun: "dar", symbol: "🐳", word: "distant" },
    { noun: "dor", symbol: "🚪", word: "portal to" },
    { noun: "lor", symbol: "📜", word: "historied" },
    { noun: "hod", symbol: "🏘", word: "collective" },
    { noun: "fol", symbol: "📂", word: "amasser of" },
    { noun: "rin", symbol: "💍", word: "vowed" },
    { noun: "tog", symbol: "🔮", word: "foreseen" },
    { noun: "sil", symbol: "🙃", word: "fool's" },
    { noun: "mir", symbol: "🪞", word: "parallel" },
    { noun: "hol", symbol: "⛳", word: "final" },
    { noun: "pas", symbol: "🛂", word: "identifying" },
    { noun: "lac", symbol: "🥛", word: "neutral" },
    { noun: "rov", symbol: "🦅", word: "roving" },
    { noun: "liv", symbol: "🫒", word: "nourishing" },
    { noun: "dal", symbol: "🪙", word: "golden" },
    { noun: "sat", symbol: "🛰", word: "visionary" },
    { noun: "lib", symbol: "📚", word: "documented" },
    { noun: "tab", symbol: "🔖", word: "significant" },
    { noun: "han", symbol: "✋", word: "dexterous" },
    { noun: "tic", symbol: "🎟", word: "admitted" },
    { noun: "pid", symbol: "🦑", word: "leviathan" },
    { noun: "tor", symbol: "🧅", word: "layered" },
    { noun: "bol", symbol: "🥣", word: "vessel for" },
    { noun: "fos", symbol: "🤼", word: "antagonist" },
    { noun: "dot", symbol: "🔘", word: "instance of" },
    { noun: "los", symbol: "⚰", word: "loss of" },
    { noun: "dil", symbol: "🌿", word: "savory" },
    { noun: "for", symbol: "🐸", word: "fugitive" },
    { noun: "pil", symbol: "💊", word: "supposed" },
    { noun: "ram", symbol: "🐏", word: "combative" },
    { noun: "tir", symbol: "🐅", word: "hidden" },
    { noun: "win", symbol: "🏆", word: "triumphant" },
    { noun: "tad", symbol: "🤏", word: "miniscule" },
    { noun: "bic", symbol: "🏍", word: "roaming" },
    { noun: "dif", symbol: "🆚", word: "battling" },
    { noun: "roc", symbol: "🪨", word: "rugged" },
    { noun: "wid", symbol: "🌠", word: "wishful" },
    { noun: "bis", symbol: "🦬", word: "cultivated" },
    { noun: "das", symbol: "🌀", word: "turbulent" },
    { noun: "mid", symbol: "💧", word: "laminar" },
    { noun: "lop", symbol: "🦞", word: "behavioral" },
    { noun: "ril", symbol: "🌄", word: "rising" },
    { noun: "nar", symbol: "🧿", word: "blinding" },
    { noun: "dap", symbol: "💖", word: "handsome" },
    { noun: "mol", symbol: "🌷", word: "manic" },
    { noun: "san", symbol: "🎅", word: "jolly" },
    { noun: "loc", symbol: "🔒", word: "restricted" },
    { noun: "nov", symbol: "🈵", word: "occupied" },
    { noun: "sit", symbol: "💩", word: "residual" },
    { noun: "nid", symbol: "🧩", word: "fragmented" },
    { noun: "tip", symbol: "💵", word: "gratuitous" },
    { noun: "sic", symbol: "🤒", word: "weakened" },
    { noun: "rop", symbol: "🪳", word: "resilient" },
    { noun: "wit", symbol: "🥀", word: "expired" },
    { noun: "nat", symbol: "🎁", word: "gift of" },
    { noun: "pan", symbol: "🐼", word: "endangered" },
    { noun: "min", symbol: "➖", word: "absence of" },
    { noun: "rit", symbol: "💥", word: "destroyer of" },
    { noun: "pod", symbol: "🐛", word: "enslaved" },
    { noun: "mot", symbol: "🦋", word: "holometabolic" },
    { noun: "tam", symbol: "™", word: "mark of" },
    { noun: "tol", symbol: "🔔", word: "herald of" },
    { noun: "sav", symbol: "💾", word: "archived" },
    { noun: "pos", symbol: "📯", word: "bringer of" },
    { noun: "nap", symbol: "💤", word: "slumbering" },
    { noun: "nop", symbol: "🙅", word: "declined" },
    { noun: "som", symbol: "🖌", word: "painted" },
    { noun: "fin", symbol: "🐬", word: "transcendental" },
    { noun: "fon", symbol: "☎", word: "networked" },
    { noun: "ban", symbol: "🚫", word: "forbidden" },
    { noun: "mor", symbol: "🔁", word: "perpetual" },
    { noun: "wor", symbol: "🌍", word: "ephemeral" },
    { noun: "sip", symbol: "🥃", word: "nursed" },
    { noun: "ron", symbol: "🥚", word: "early" },
    { noun: "nor", symbol: "⬆", word: "ascendant" },
    { noun: "bot", symbol: "🤖", word: "mechanical" },
    { noun: "wic", symbol: "🕯", word: "illuminating" },
    { noun: "soc", symbol: "🧦", word: "matching" },
    { noun: "wat", symbol: "❓", word: "perplexed" },
    { noun: "dol", symbol: "🎎", word: "imitation" },
    { noun: "mag", symbol: "🚅", word: "expedient" },
    { noun: "pic", symbol: "🖼", word: "framed" },
    { noun: "dav", symbol: "🤴", word: "king's" },
    { noun: "bid", symbol: "👨‍", word: "offered" },
    { noun: "bal", symbol: "🖲", word: "articulated" },
    { noun: "tim", symbol: "🔱", word: "triple" },
    { noun: "tas", symbol: "♨", word: "boiling" },
    { noun: "mal", symbol: "😈", word: "malicious" },
    { noun: "lig", symbol: "✍", word: "meticulous" },
    { noun: "siv", symbol: "👁", word: "subjective" },
    { noun: "tag", symbol: "🏷", word: "marked" },
    { noun: "pad", symbol: "🏠", word: "settled" },
    { noun: "sal", symbol: "🧂", word: "frustrated" },
    { noun: "div", symbol: "➗", word: "separated" },
    { noun: "dac", symbol: "👤", word: "shadow of" },
    { noun: "tan", symbol: "🍊", word: "citric" },
    { noun: "sid", symbol: "🗣", word: "voluble" },
    { noun: "fab", symbol: "🦄", word: "flamboyant" },
    { noun: "tar", symbol: "*️⃣", word: "surrogate" },
    { noun: "mon", symbol: "🐵", word: "simian" },
    { noun: "ran", symbol: "🏃", word: "hurried" },
    { noun: "nis", symbol: "❌", word: "incongruent" },
    { noun: "wol", symbol: "🦉", word: "symbolic" },
    { noun: "mis", symbol: "🎊", word: "festive" },
    { noun: "pal", symbol: "🙂", word: "friendly" },
    { noun: "las", symbol: "👧", word: "youthful" },
    { noun: "dis", symbol: "⛔", word: "rejected" },
    { noun: "map", symbol: "🗺", word: "guiding" },
    { noun: "rab", symbol: "🐇", word: "fertile" },
    { noun: "tob", symbol: "🪃", word: "return of" },
    { noun: "rol", symbol: "🌯", word: "packaged" },
    { noun: "lat", symbol: "🦤", word: "extinct" },
    { noun: "lon", symbol: "🌐", word: "longitudinal" },
    { noun: "nod", symbol: "😪", word: "exhausted" },
    { noun: "nav", symbol: "➡", word: "directional" },
    { noun: "fig", symbol: "🗿", word: "monumental" },
    { noun: "nom", symbol: "🍕", word: "edible" },
    { noun: "nib", symbol: "⏫", word: "improved" },
    { noun: "pag", symbol: "📟", word: "indicative" },
    { noun: "sop", symbol: "🧼", word: "cleansed" },
    { noun: "ral", symbol: "😎", word: "radical" },
    { noun: "bil", symbol: "💶", word: "owed" },
    { noun: "had", symbol: "🈹", word: "discounted" },
    { noun: "doc", symbol: "🧑‍", word: "shamanic" },
    { noun: "rid", symbol: "🎀", word: "giver of" },
    { noun: "moc", symbol: "🦈", word: "apex" },
    { noun: "pac", symbol: "🌝", word: "ravenous" },
    { noun: "rav", symbol: "☢", word: "decaying" },
    { noun: "rip", symbol: "🪦", word: "perished" },
    { noun: "fal", symbol: "🍂", word: "waning" },
    { noun: "tod", symbol: "👶", word: "born" },
    { noun: "til", symbol: "🚜", word: "worker of" },
    { noun: "tin", symbol: "🥫", word: "preserved" },
    { noun: "hap", symbol: "🐣", word: "fledgling" },
    { noun: "mic", symbol: "🎤", word: "recorded" },
    { noun: "fan", symbol: "🐘", word: "learned" },
    { noun: "pat", symbol: "🌳", word: "natural" },
    { noun: "tac", symbol: "📌", word: "fastened" },
    { noun: "lab", symbol: "🧪", word: "experimental" },
    { noun: "mog", symbol: "💪", word: "impressive" },
    { noun: "sim", symbol: "👨‍", word: "subject to" },
    { noun: "son", symbol: "👨‍", word: "heir to" },
    { noun: "pin", symbol: "📍", word: "located" },
    { noun: "lom", symbol: "👽", word: "demonic" },
    { noun: "ric", symbol: "🦗", word: "restless" },
    { noun: "tap", symbol: "🚰", word: "dispenser of" },
    { noun: "fir", symbol: "🌲", word: "appeal to" },
    { noun: "has", symbol: "📫", word: "recipient of" },
    { noun: "bos", symbol: "✝", word: "absolute" },
    { noun: "bat", symbol: "🦇", word: "echoing" },
    { noun: "poc", symbol: "🆔", word: "unique" },
    { noun: "hac", symbol: "🕶", word: "background" },
    { noun: "tid", symbol: "🧄", word: "potent" },
    { noun: "hav", symbol: "✊", word: "grasped" },
    { noun: "sap", symbol: "🩸", word: "vital" },
    { noun: "lin", symbol: "🔗", word: "attached" },
    { noun: "dib", symbol: "🥇", word: "prioritized" },
    { noun: "hos", symbol: "🐎", word: "equestrian" },
    { noun: "dab", symbol: "💯", word: "maximal" },
    { noun: "bit", symbol: "🫦", word: "suggestive" },
    { noun: "bar", symbol: "🍻", word: "hospitable" },
    { noun: "rac", symbol: "🦝", word: "thieving" },
    { noun: "par", symbol: "🦜", word: "talkative" },
    { noun: "lod", symbol: "🔊", word: "deafening" },
    { noun: "dos", symbol: "🐫", word: "double" },
    { noun: "bor", symbol: "🐗", word: "strongwilled" },
    { noun: "toc", symbol: "📑", word: "arranged" },
    { noun: "hil", symbol: "⛰", word: "unyielding" },
    { noun: "mac", symbol: "🍏", word: "newtonian" },
    { noun: "tom", symbol: "🥁", word: "rumbling" },
    { noun: "dig", symbol: "🅱", word: "daring" },
    { noun: "fil", symbol: "🐡", word: "expansive" },
    { noun: "fas", symbol: "👀", word: "observed" },
    { noun: "mit", symbol: "🏫", word: "scholastic" },
    { noun: "hob", symbol: "🗞", word: "punished" },
    { noun: "har", symbol: "🫀", word: "rhythmic" },
    { noun: "mig", symbol: "🪄", word: "vanishing" },
    { noun: "hin", symbol: "🐈‍", word: "unfortunate" },
    { noun: "rad", symbol: "🤙", word: "tubular" },
    { noun: "mas", symbol: "🎭", word: "masquerading" },
    { noun: "hal", symbol: "🎯", word: "particular" },
    { noun: "rag", symbol: "🧻", word: "sweeping" },
    { noun: "lag", symbol: "⏳", word: "behindhand" },
    { noun: "fad", symbol: "🆕", word: "trending" },
    { noun: "top", symbol: "🔝", word: "ascended" },
    { noun: "mop", symbol: "🧽", word: "retainer of" },
    { noun: "hab", symbol: "🔎", word: "seeker of" },
    { noun: "nil", symbol: "0️⃣", word: "nullifier of" },
    { noun: "nos", symbol: "👃", word: "intuitive" },
    { noun: "mil", symbol: "🌾", word: "processor of" },
    { noun: "fop", symbol: "🎰", word: "fortuitous" },
    { noun: "fam", symbol: "👨‍", word: "tribal" },
    { noun: "dat", symbol: "💿", word: "digitized" },
    { noun: "nol", symbol: "🎞", word: "analog" },
    { noun: "din", symbol: "🍲", word: "satisfying" },
    { noun: "hat", symbol: "🎩", word: "dignified" },
    { noun: "nac", symbol: "🪟", word: "transparent" },
    { noun: "ris", symbol: "🧗", word: "mounting" },
    { noun: "fot", symbol: "📷", word: "captured" },
    { noun: "rib", symbol: "🍖", word: "scrumptious" },
    { noun: "hoc", symbol: "🏑", word: "competitive" },
    { noun: "nim", symbol: "🪁", word: "soaring" },
    { noun: "lar", symbol: "🦙", word: "corpulent" },
    { noun: "fit", symbol: "🏋", word: "lifter of" },
    { noun: "wal", symbol: "🧱", word: "obstructive" },
    { noun: "rap", symbol: "🤬", word: "censored" },
    { noun: "sar", symbol: "🪢", word: "eclectic" },
    { noun: "nal", symbol: "✨", word: "magnificant" },
    { noun: "mos", symbol: "🦟", word: "humming" },
    { noun: "lan", symbol: "⛓", word: "connected" },
    { noun: "don", symbol: "👕", word: "wearer of" },
    { noun: "dan", symbol: "🍡", word: "sweetened" },
    { noun: "lad", symbol: "👦", word: "junior" },
    { noun: "dov", symbol: "🕊", word: "spirit of" },
    { noun: "riv", symbol: "🌊", word: "cascading" },
    { noun: "bac", symbol: "🧮", word: "adder of" },
    { noun: "pol", symbol: "👮", word: "upholder of" },
    { noun: "lap", symbol: "🔂", word: "repeated" },
    { noun: "tal", symbol: "⛹", word: "towering" },
    { noun: "pit", symbol: "🕳", word: "abyssal" },
    { noun: "nam", symbol: "📛", word: "named" },
    { noun: "bon", symbol: "🦴", word: "skeletal" },
    { noun: "ros", symbol: "🌹", word: "passionate" },
    { noun: "ton", symbol: "👅", word: "toothsome" },
    { noun: "fod", symbol: "🫕", word: "molten" },
    { noun: "pon", symbol: "🐴", word: "stout" },
    { noun: "sov", symbol: "🗡", word: "wielder of" },
    { noun: "noc", symbol: "🔢", word: "executive" },
    { noun: "sor", symbol: "🌪", word: "forceful" },
    { noun: "lav", symbol: "🚽", word: "sanctuary of" },
    { noun: "mat", symbol: "⚛", word: "substantial" },
    { noun: "mip", symbol: "🐁", word: "squeaking" },
    { noun: "fip", symbol: "🩴", word: "flopping" },
  ];
  const suffix = [
    { noun: "zod", symbol: "⚫", word: "one" },
    { noun: "nec", symbol: "🐍", word: "whisperer" },
    { noun: "bud", symbol: "🌱", word: "potentiality" },
    { noun: "wes", symbol: "⬅", word: "escape" },
    { noun: "sev", symbol: "💰", word: "opulence" },
    { noun: "per", symbol: "🌶", word: "spice" },
    { noun: "sut", symbol: "🦸", word: "heroism" },
    { noun: "let", symbol: "🚸", word: "yielding" },
    { noun: "ful", symbol: "🥛", word: "satiety" },
    { noun: "pen", symbol: "✏", word: "writing" },
    { noun: "syt", symbol: "🖥", word: "presentation" },
    { noun: "dur", symbol: "☠", word: "doom" },
    { noun: "wep", symbol: "🦫", word: "engineer" },
    { noun: "ser", symbol: "🔀", word: "chaos" },
    { noun: "wyl", symbol: "⚙", word: "enabler" },
    { noun: "sun", symbol: "🌞", word: "exuberance" },
    { noun: "ryp", symbol: "🍌", word: "maturity" },
    { noun: "syx", symbol: "🃏", word: "wildcard" },
    { noun: "dyr", symbol: "‼", word: "cognizance" },
    { noun: "nup", symbol: "🗨", word: "shibboleth" },
    { noun: "heb", symbol: "🩸", word: "blood" },
    { noun: "peg", symbol: "📌", word: "adhesion" },
    { noun: "lup", symbol: "❌", word: "denial" },
    { noun: "dep", symbol: "🐕‍", word: "assistant" },
    { noun: "dys", symbol: "🎲", word: "chance" },
    { noun: "put", symbol: "⛳", word: "completion" },
    { noun: "lug", symbol: "🧳", word: "possessions" },
    { noun: "hec", symbol: "🪀", word: "bandalore" },
    { noun: "ryt", symbol: "➡", word: "signifyer" },
    { noun: "tyv", symbol: "🦠", word: "disease" },
    { noun: "syd", symbol: "🧬", word: "programming" },
    { noun: "nex", symbol: "🔜", word: "destiny" },
    { noun: "lun", symbol: "🌝", word: "satellite" },
    { noun: "mep", symbol: "✉", word: "post" },
    { noun: "lut", symbol: "🧰", word: "utility" },
    { noun: "sep", symbol: "↔", word: "exchange" },
    { noun: "pes", symbol: "🐟", word: "pisces" },
    { noun: "del", symbol: "⚗", word: "purifier" },
    { noun: "sul", symbol: "💀", word: "expiration" },
    { noun: "ped", symbol: "🚶", word: "wandering" },
    { noun: "tem", symbol: "🌡", word: "degree" },
    { noun: "led", symbol: "🚥", word: "directionality" },
    { noun: "tul", symbol: "🌷", word: "aesthete" },
    { noun: "met", symbol: "📐", word: "determination" },
    { noun: "wen", symbol: "🕓", word: "time" },
    { noun: "byn", symbol: "♾", word: "eternities" },
    { noun: "hex", symbol: "🧙‍", word: "curse" },
    { noun: "feb", symbol: "🧀", word: "fermentation" },
    { noun: "pyl", symbol: "📚", word: "chronicles" },
    { noun: "dul", symbol: "☁", word: "monotony" },
    { noun: "het", symbol: "🚫", word: "limits" },
    { noun: "mev", symbol: "⚖", word: "balance" },
    { noun: "rut", symbol: "🔁", word: "perpetuity" },
    { noun: "tyl", symbol: "🀄", word: "representation" },
    { noun: "wyd", symbol: "🌌", word: "space" },
    { noun: "tep", symbol: "🤬", word: "expletive" },
    { noun: "bes", symbol: "🏹", word: "hunter" },
    { noun: "dex", symbol: "👆", word: "pointer" },
    { noun: "sef", symbol: "🤳", word: "reflection" },
    { noun: "wyc", symbol: "🧾", word: "values" },
    { noun: "bur", symbol: "🖨", word: "replication" },
    { noun: "der", symbol: "🦌", word: "prey" },
    { noun: "nep", symbol: "📰", word: "communique" },
    { noun: "pur", symbol: "🐱", word: "feline" },
    { noun: "rys", symbol: "🍚", word: "staple" },
    { noun: "reb", symbol: "🐀", word: "plague" },
    { noun: "den", symbol: "🍢", word: "victuals" },
    { noun: "nut", symbol: "🥜", word: "legumes" },
    { noun: "sub", symbol: "➖", word: "absence" },
    { noun: "pet", symbol: "🐶", word: "companion" },
    { noun: "rul", symbol: "🪧", word: "indication" },
    { noun: "syn", symbol: "🌈", word: "covenant" },
    { noun: "reg", symbol: "👑", word: "sovereign" },
    { noun: "tyd", symbol: "🌊", word: "torrent" },
    { noun: "sup", symbol: "🏄", word: "shredder" },
    { noun: "sem", symbol: "🎌", word: "semaphore" },
    { noun: "wyn", symbol: "🍷", word: "vintage" },
    { noun: "rec", symbol: "🔴", word: "recording" },
    { noun: "meg", symbol: "🗄", word: "database" },
    { noun: "net", symbol: "🌐", word: "worlds" },
    { noun: "sec", symbol: "👮", word: "safekeeper" },
    { noun: "mul", symbol: "🤔", word: "contemplation" },
    { noun: "nym", symbol: "📛", word: "nomenclature" },
    { noun: "tev", symbol: "👕", word: "garnments" },
    { noun: "web", symbol: "🕸", word: "entanglement" },
    { noun: "sum", symbol: "🗃", word: "aggregate" },
    { noun: "mut", symbol: "🐕", word: "canine" },
    { noun: "nyx", symbol: "❄", word: "singleton" },
    { noun: "rex", symbol: "🦖", word: "king" },
    { noun: "teb", symbol: "🏰", word: "stronghold" },
    { noun: "fus", symbol: "😠", word: "confrontation" },
    { noun: "hep", symbol: "❇", word: "deliverance" },
    { noun: "ben", symbol: "🕰", word: "hour" },
    { noun: "mus", symbol: "🏛", word: "antiquity" },
    { noun: "wyx", symbol: "🎯", word: "objective" },
    { noun: "sym", symbol: "🦉", word: "mystery" },
    { noun: "sel", symbol: "🧂", word: "condiment" },
    { noun: "ruc", symbol: "🪆", word: "recursion" },
    { noun: "dec", symbol: "⤵", word: "reduction" },
    { noun: "wex", symbol: "🗻", word: "trial" },
    { noun: "syr", symbol: "⚔", word: "knight" },
    { noun: "wet", symbol: "💦", word: "immersion" },
    { noun: "dyl", symbol: "✨", word: "ideals" },
    { noun: "myn", symbol: "🧠", word: "mind" },
    { noun: "mes", symbol: "🔪", word: "partitioning" },
    { noun: "det", symbol: "🌳", word: "life" },
    { noun: "bet", symbol: "💸", word: "speculation" },
    { noun: "bel", symbol: "🔔", word: "herald" },
    { noun: "tux", symbol: "🐧", word: "kernel" },
    { noun: "tug", symbol: "🫀", word: "heart" },
    { noun: "myr", symbol: "🍯", word: "ambrosia" },
    { noun: "pel", symbol: "🫑", word: "greens" },
    { noun: "syp", symbol: "🤡", word: "ridicule" },
    { noun: "ter", symbol: "🎸", word: "legend" },
    { noun: "meb", symbol: "👤", word: "generalities" },
    { noun: "set", symbol: "🤞", word: "hope" },
    { noun: "dut", symbol: "💢", word: "pressure" },
    { noun: "deg", symbol: "🍆", word: "debauchery" },
    { noun: "tex", symbol: "🤠", word: "independence" },
    { noun: "sur", symbol: "🏳", word: "surrender" },
    { noun: "fel", symbol: "😡", word: "rage" },
    { noun: "tud", symbol: "♠", word: "spades" },
    { noun: "nux", symbol: "♥", word: "hearts" },
    { noun: "rux", symbol: "♦", word: "diamonds" },
    { noun: "ren", symbol: "♣", word: "clubs" },
    { noun: "wyt", symbol: "⚪", word: "purity" },
    { noun: "nub", symbol: "🔘", word: "protrusion" },
    { noun: "med", symbol: "⚕", word: "panacea" },
    { noun: "lyt", symbol: "💡", word: "radiance" },
    { noun: "dus", symbol: "💨", word: "particulates" },
    { noun: "neb", symbol: "🔰", word: "beginnings" },
    { noun: "rum", symbol: "🥃", word: "spirits" },
    { noun: "tyn", symbol: "🎐", word: "tranquility" },
    { noun: "seg", symbol: "🧩", word: "segment" },
    { noun: "lyx", symbol: "🐈", word: "predator" },
    { noun: "pun", symbol: "⛎", word: "meta" },
    { noun: "res", symbol: "🈯", word: "reservation" },
    { noun: "red", symbol: "🍎", word: "crimson" },
    { noun: "fun", symbol: "😂", word: "hysteria" },
    { noun: "rev", symbol: "❤️‍", word: "revelation" },
    { noun: "ref", symbol: "🪑", word: "furniture" },
    { noun: "mec", symbol: "🩹", word: "mending" },
    { noun: "ted", symbol: "🪂", word: "thrill" },
    { noun: "rus", symbol: "🐚", word: "husk" },
    { noun: "bex", symbol: "♨", word: "oracle" },
    { noun: "leb", symbol: "🪵", word: "material" },
    { noun: "dux", symbol: "🦆", word: "waterfowl" },
    { noun: "ryn", symbol: "🦏", word: "panzer" },
    { noun: "num", symbol: "🦬", word: "stampede" },
    { noun: "pyx", symbol: "🧚", word: "trickster" },
    { noun: "ryg", symbol: "⚡", word: "force" },
    { noun: "ryx", symbol: "🐝", word: "monarchy" },
    { noun: "fep", symbol: "📴", word: "retreat" },
    { noun: "tyr", symbol: "🛞", word: "locomotion" },
    { noun: "tus", symbol: "🦣", word: "mammoth" },
    { noun: "tyc", symbol: "⚠", word: "attention" },
    { noun: "leg", symbol: "🦵", word: "support" },
    { noun: "nem", symbol: "🧲", word: "attractor" },
    { noun: "fer", symbol: "🌲", word: "appeal" },
    { noun: "mer", symbol: "🧜‍", word: "amalgam" },
    { noun: "ten", symbol: "🔟", word: "decimal" },
    { noun: "lus", symbol: "➕", word: "additive" },
    { noun: "nus", symbol: "💭", word: "nous" },
    { noun: "syl", symbol: "🗝", word: "solution" },
    { noun: "tec", symbol: "💻", word: "gateway" },
    { noun: "mex", symbol: "🌮", word: "folding" },
    { noun: "pub", symbol: "🍻", word: "conviviality" },
    { noun: "rym", symbol: "📝", word: "poetry" },
    { noun: "tuc", symbol: "🦃", word: "thanksgiving" },
    { noun: "fyl", symbol: "💕", word: "lover" },
    { noun: "lep", symbol: "🐆", word: "panther" },
    { noun: "deb", symbol: "🤵", word: "debonair" },
    { noun: "ber", symbol: "🐻", word: "ursid" },
    { noun: "mug", symbol: "🧺", word: "container" },
    { noun: "hut", symbol: "🛖", word: "compound" },
    { noun: "tun", symbol: "📻", word: "attunement" },
    { noun: "byl", symbol: "🤮", word: "expeller" },
    { noun: "sud", symbol: "📼", word: "excuse" },
    { noun: "pem", symbol: "♟", word: "pawn" },
    { noun: "dev", symbol: "👨‍", word: "conductor" },
    { noun: "lur", symbol: "🎣", word: "catch" },
    { noun: "def", symbol: "🙉", word: "ignoramus" },
    { noun: "bus", symbol: "🚌", word: "carriage" },
    { noun: "bep", symbol: "🔊", word: "cacophony" },
    { noun: "run", symbol: "🏃", word: "strider" },
    { noun: "mel", symbol: "🫠", word: "disarray" },
    { noun: "pex", symbol: "💪", word: "strongman" },
    { noun: "dyt", symbol: "🌛", word: "crepuscule" },
    { noun: "byt", symbol: "🦈", word: "masticator" },
    { noun: "typ", symbol: "⌨", word: "translator" },
    { noun: "lev", symbol: "🚅", word: "conveyance" },
    { noun: "myl", symbol: "🏎", word: "speedster" },
    { noun: "wed", symbol: "🌿", word: "herb" },
    { noun: "duc", symbol: "💫", word: "vertigo" },
    { noun: "fur", symbol: "🐺", word: "anthropomorph" },
    { noun: "fex", symbol: "🧯", word: "extinguisher" },
    { noun: "nul", symbol: "0️⃣", word: "void" },
    { noun: "luc", symbol: "🍀", word: "fortune" },
    { noun: "len", symbol: "📏", word: "extent" },
    { noun: "ner", symbol: "🦥", word: "sloth" },
    { noun: "lex", symbol: "💬", word: "gab" },
    { noun: "rup", symbol: "🥠", word: "aphorism" },
    { noun: "ned", symbol: "🇳", word: "lowlands" },
    { noun: "lec", symbol: "😈", word: "sophistry" },
    { noun: "ryd", symbol: "🏇", word: "roughrider" },
    { noun: "lyd", symbol: "☀", word: "light" },
    { noun: "fen", symbol: "🪟", word: "exposition" },
    { noun: "wel", symbol: "🎻", word: "elegance" },
    { noun: "nyd", symbol: "😤", word: "accomplishment" },
    { noun: "hus", symbol: "🤫", word: "secrecy" },
    { noun: "rel", symbol: "🌋", word: "cataclysm" },
    { noun: "rud", symbol: "💃", word: "boogaloo" },
    { noun: "nes", symbol: "🕹", word: "diversion" },
    { noun: "hes", symbol: "🌬", word: "breath" },
    { noun: "fet", symbol: "☣", word: "contaminant" },
    { noun: "des", symbol: "👁", word: "providence" },
    { noun: "ret", symbol: "🎱", word: "outlook" },
    { noun: "dun", symbol: "🏜", word: "wasteland" },
    { noun: "ler", symbol: "⏏", word: "emission" },
    { noun: "nyr", symbol: "🥄", word: "provider" },
    { noun: "seb", symbol: "🏠", word: "abode" },
    { noun: "hul", symbol: "🚢", word: "vessel" },
    { noun: "ryl", symbol: "🧭", word: "exploration" },
    { noun: "lud", symbol: "👴", word: "luddite" },
    { noun: "rem", symbol: "🛡", word: "protector" },
    { noun: "lys", symbol: "🤥", word: "untruths" },
    { noun: "fyn", symbol: "💎", word: "rarity" },
    { noun: "wer", symbol: "🌦", word: "conditions" },
    { noun: "ryc", symbol: "🗽", word: "liberty" },
    { noun: "sug", symbol: "🍬", word: "confection" },
    { noun: "nys", symbol: "👍", word: "approval" },
    { noun: "nyl", symbol: "🏕", word: "conqueror" },
    { noun: "lyn", symbol: "🦁", word: "pride" },
    { noun: "dyn", symbol: "🤸", word: "dynamism" },
    { noun: "dem", symbol: "🐴", word: "democracy" },
    { noun: "lux", symbol: "🔆", word: "luminosity" },
    { noun: "fed", symbol: "🕴", word: "spook" },
    { noun: "sed", symbol: "🌰", word: "genesis" },
    { noun: "bec", symbol: "🥚", word: "kiln" },
    { noun: "mun", symbol: "🌚", word: "orbit" },
    { noun: "lyr", symbol: "🎶", word: "ballad" },
    { noun: "tes", symbol: "🔬", word: "examination" },
    { noun: "mud", symbol: "🏍", word: "grime" },
    { noun: "nyt", symbol: "🌃", word: "eventide" },
    { noun: "byr", symbol: "🧊", word: "preservation" },
    { noun: "sen", symbol: "💠", word: "principality" },
    { noun: "weg", symbol: "👻", word: "possession" },
    { noun: "fyr", symbol: "🔥", word: "inferno" },
    { noun: "mur", symbol: "🗿", word: "resolve" },
    { noun: "tel", symbol: "☎", word: "mediator" },
    { noun: "rep", symbol: "🐘", word: "republic" },
    { noun: "teg", symbol: "🔭", word: "gaze" },
    { noun: "pec", symbol: "🐦", word: "avian" },
    { noun: "nel", symbol: "🤺", word: "swashbuckler" },
    { noun: "nev", symbol: "🔱", word: "authority" },
    { noun: "fes", symbol: "🎉", word: "celebration" },
  ];

  const p1 = prefix.find((p) => p.noun == pre1);
  const s1 = suffix.find((p) => p.noun == suf1);
  const p2 = prefix.find((p) => p.noun == pre2);
  const s2 = suffix.find((p) => p.noun == suf2);

  if (isGalaxy) {
    const g = suffix.find((g) => g.noun == pre1);
    return [g, {}, {}, {}];
  } else {
    return [p1, s1, p2, s2];
  }
};
