import React, { useState, useEffect } from "react";
import NavBarLanding from "../components/NavBarLanding";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import Footer from "../components/Footer";
import TouchableOpacity from "../components/TouchableOpacity";

const LandingPage = () => {
  const [isVisibleHeader, setIsVisibleHeader] = useState(false);
  const [isVisibleText, setIsVisibleText] = useState(false);
  const history = useHistory();

  const bgImage =
    "https://images.hdqwalls.com/download/life-cottage-aurora-northern-lights-lj-1920x1080.jpg";

  useEffect(() => {
    // document.body.style.backgroundColor = "#f9f6f2";

    // document.body.style.backgroundColor = "rgb(51,51,51)";
    document.body.style.background = "rgba(249,246,242,1)";
    // "linear-gradient(0deg, rgba(51,51,51,0.97) 5%, rgba(249,246,242,1) 53%)";

    // document.body.style.background = "rgb(33,85,240)";
    // document.body.style.background =
    //   "linear-gradient(132deg, rgba(33,85,240,0.22735031512605042) 33%, rgba(249,246,242,1) 33%)";

    // document.body.style.background = "rgb(147,197,114)";
    // document.body.style.background =
    //   "linear-gradient(149deg, rgba(147,197,114,1) 0%, rgba(249,246,242,1) 33%)";

    // background: "linear-gradient(to bottom, #fff, #f9f6f2)",
    setIsVisibleHeader(true);
    setTimeout(() => {
      setIsVisibleText(true);
    }, 100);
    return () => {
      document.body.style.backgroundColor = "#fff";
      document.body.style.background = "#fff";
    };
  }, []);

  return (
    <>
      <NavBarLanding />
      <Container fluid>
        {" "}
        <Row
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingLeft: "10%",
            paddingRight: "10%",
            paddingBottom: "5%",
            // paddingTop: "2.5%",
            paddingTop: 36,
          }}
        >
          <Col style={{ paddingLeft: "2.5%", color: "#292b2c" }}>
            <Row>
              <h1
                style={{
                  fontFamily: "Raleway",
                  fontWeight: 800,
                  fontSize: 65,
                  opacity: isVisibleHeader ? 1 : 0,
                  transition: "opacity 0.5s ease-in-out",
                  // color: "#69b3e7",
                  color: "#292b2c",
                  // background: "linear-gradient(to right, #0093E9, #80D0C7)",
                  background: "linear-gradient(to right, #0093E9, #c982cf)",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                  // paddingTop: 75,
                }}
              >
                Urbit's trading platform.
                {/* Buy and sell{" "}
                <a
                  style={{
                    background: "linear-gradient(to bottom, #fff, #0275d8)",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                  }}
                >
                  Urbit
                </a>{" "}
                stars and planets. */}
              </h1>
            </Row>

            <Row style={{ marginTop: 20 }}>
              <h3
                style={{
                  fontFamily: "Raleway",
                  fontWeight: 600,
                  fontSize: 20,
                  opacity: isVisibleText ? 1 : 0,
                  transition: "opacity 0.5s ease-in-out",
                  color: "#A2A1A0",
                  // color: "#69b3e7",
                }}
              >
                Buy and sell Urbit planets and stars.
                {/* <br /> */}
                {/* Swap instantly with decentralized liquidity pools. */}
              </h3>
            </Row>
            <Row
              style={{
                padding: "2.5%",
                marginTop: 30,
                alignItems: "center",
                justifyContent: "flex-start",
                display: "flex",
              }}
            >
              {/* <Button
                className="btn btn-primary"
                onClick={() => history.push("/swap")}
                style={{
                  fontFamily: "Raleway",
                  fontWeight: 800,
                  fontSize: 18,
                  marginTop: 0,
                  // backgroundColor: "#0093E9",
                  // borderColor: "#416b66",
                  width: "35%",
                  minWidth: 130,
                  borderRadius: 5,
                  marginRight: 15,
                  height: 50,
                }}
              >
                Swap stars
              </Button> */}

              <Button
                className="btn btn-primary"
                onClick={() => history.push("/market")}
                style={{
                  fontFamily: "Raleway",
                  fontWeight: 800,
                  fontSize: 18,
                  marginTop: 0,
                  // backgroundColor: "rgba(0, 147, 233, 0.0)",
                  // borderColor: "#0275d8",
                  // color: "#0275d8",
                  width: "100%",
                  maxWidth: 300,
                  // width: 250,
                  minWidth: 130,
                  // width: 170,
                  borderRadius: 5,
                  height: 50,
                }}
              >
                Browse Marketplace
              </Button>
            </Row>
          </Col>
          <Col style={{ paddingRight: "2.5%" }}>
            <img
              style={{
                borderRadius: 10,
                minWidth: 300,
                width: "100%",
                maxWidth: "100%",
                height: "auto",
              }}
              src={require("../assets/img/colony.png").default}
            />

            <style>
              {`
  @media (min-width: 768px) {
    img {
      width: 400px;
      max-width: none;
    }
  }
  @media (max-width: 767px) {
    img {
      width: 100%;
      max-width: none;
    }
  }
`}
            </style>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default LandingPage;
