import React from "react";
import NavBar from "./NavBar";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidMount() {
    this.unlisten = this.props.history.listen((location, action) => {
      if (this.state.hasError) {
        this.setState({ hasError: false });
      }
    });
  }

  componentWillUnmount() {
    this.unlisten();
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true });
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, info);
  }

  render() {
    const { location, history } = this.props;
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <React.Fragment>
          <NavBar />
          <div style={styles.container}>
            <div
              style={{
                display: "flex",
                fontFamily: "inter",
                alignItems: "center",
                fontWeight: 1000,
                fontSize: 100,
              }}
            >
              Oops
            </div>

            <div style={{ fontSize: 30, marginBottom: 20 }}>
              Something went wrong.
            </div>
            <Link to="/" className="text-dark nounderline pr-3">
              <div
                className="btn btn-outline-dark btn-lg"
                onClick={() => history.push("/market")}
                role="button"
                style={{
                  borderRadius: 6,
                  width: 200,
                  height: 65,
                  marginTop: 50,
                  display: "flex",
                  textAlign: "center",
                  justifyContent: "center",
                  margin: 10,
                }}
              >
                <div
                  style={{
                    fontWeight: "bold",
                    padding: 8,
                  }}
                >
                  Take me back
                </div>
              </div>
            </Link>
          </div>
        </React.Fragment>
      );
    }
    return this.props.children;
  }
}

const styles = {
  container: {
    // paddingTop: 100,
    height: "100%",
    display: "flex",
    flexDirection: "column",
    // backgroundColor: "rgba(0,0,0,0.1)",
    // paddingBottom: 400,
    marginTop: 100,
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
  },
  contentContainer: {
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
  },
  header: {
    fontSize: 28,
    marginBottom: 30,
  },
};

export default withRouter(ErrorBoundary);
